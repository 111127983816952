.event-list {

  .header-admin {
    position: relative;
    left: 15px;

    .dropdown {
      border: 5px solid;
      border-radius: 12px;

      &.white {
        background-color: white;
        border-color: white;
      }

      &.silver {
        background-color: color(silver);
        border-color: color(silver);
      }

    }

    @include respond-to(medium) {
      position: absolute;
      top: auto;
      left: 15px;
    }
  }

  .thumb {
    border-radius: 20px;
    overflow: hidden;

    img {
      border: none;
      background-color: transparent;
      padding: 0;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: -10% 0;
      width: 100%;
    }
  }

  .card {
    border: none;
    background-color: transparent;

    .title {

      a {
        font-size: 16px;
        font-weight: 600;
        color: #323232;
      }
    }

    .date {
      a {
        font-size: 14px;
        font-weight: 500;
        color: #ADADAD;
      }
    }

    .description {


      a {
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }

  }

}