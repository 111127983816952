@charset "utf-8";

// Przykład, jak pokolorować kolejne elementy (tła) w danej klasie (tylko kolor)
.slide {
  $numItems: 10;
  $eachDeg: 100deg / $numItems;
  $color: color(active);
  @for $idx from 1 through $numItems {
    &:nth-child(#{$idx}) {
      background-color: adjust-hue($color, $eachDeg * $idx);
    }
  }
}

.slider-global {

  &.inner-block {
    position: relative;
    counter-increment: carousel-cell;
    padding: 0;

    .slider-item {

      .slider-content {
        width: inherit;

        // Szablony graficzne
        // 0 - transparent
        // 1 - card
        &.theme {
          &-0 {

          }

          &-1 {
            position: relative;
            background-color: white;
            padding: 15px 15px;
            border-radius: 20px;
            margin: 7px 7px;

            .owl-author {
              img {
                border-radius: 50%;
                background-color: white;
                box-shadow: 0 .250rem .50rem rgba(black, .075);
              }
            }

            &:after {
              content: "";
              box-shadow: 0 .250rem .50rem rgba(black, .075);
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              padding: 25px;
              border-radius: inherit;
              overflow: visible !important;
            }
          }

          &-2 {
            position: relative;
            background-color: white;
            padding: 15px 15px;
            border-radius: 20px;

            .owl-author {
              img {
                border-radius: 50%;
                background-color: white;
                box-shadow: 0 .250rem .50rem rgba(black, .075);
              }
            }

            &:after {
              content: "";
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              padding: 25px;
              border-radius: inherit;
              overflow: visible !important;
              background-color: #F7F7F7;
              z-index: 0;
            }
          }
        }

        .inner-header-block {
          top: -3.5%;
          flex-wrap: wrap;

          @include respond-to(medium) {
            flex-wrap: inherit;
          }

          .slider-header-admin {
            position: relative;
            left: 15px;

            @include respond-to(medium) {
              position: absolute;
              top: auto;
              left: 15px;
            }

            .button-admin {
              padding: 0.7vw 2vw;
              font-size: 1.5vw;

              @include respond-to(medium) {
                font-size: 1vw;
              }

              @include respond-to(large) {
                font-size: inherit;
                padding: 0.35vw 1.2vw;
              }

              @include respond-to(big) {
                font-size: inherit;
                padding: 0.35vw 0.7vw;
              }
            }


          }

          .slider-header-label {
            position: relative;
            font-size: 1.4vw;
            right: 0;

            @include respond-to(medium) { // 690 w kolumnie,
              position: absolute;
              right: 15px;
              font-size: 0.6vw;
            }

            .label {
              box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
              letter-spacing: -0.25px;
              font-weight: 550;
              border-radius: 12px;
              border: 2px solid #F5F5F5;
              padding: 0.5vw 1vw;

              @include respond-to(medium) { // 690 w kolumnie,
                border: 4px solid #F5F5F5;
              }
            }
          }
        }

        .inner-title-block {
          position: relative;
          z-index: 1;

          .slider-title-text {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0 !important;
            height: 2.4em;

          }

          .slider-title-text-wrap {
            max-height: 3.6em;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
        }

        .inner-description-block {
          position: relative;
          z-index: 1;

          .slider-description-text {
            font-size: 12px;
            margin-bottom: 0 !important;
            height: 4.8em;

          }

          .slider-description-text-wrap {
            max-height: 5em;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
        }

        .inner-content-block {
          position: relative;
          z-index: 1;
        }

        .inner-thumb-block {
          position: relative;
          z-index: 1;
          width: 100%;

          .thumb {
            border-radius: 20px;
            overflow: hidden;

            img {
              border: none;
              background-color: transparent;
              padding: 0;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              margin: -10% 0;
              width: 100%;
            }
          }

          .thumb-resource {
            border-radius: 20px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              background-color: transparent;

            }
          }

          svg.absolute {
            opacity: 0.6;
          }

          .label {
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            max-width: calc(100% - 20px);
            border-width: 1px 1px 1px 1px;
            border-style: solid;
            border-radius: 0 2px 0 10px;
            border-left-color: color(white-light);
            border-bottom-color: color(white-light);
            padding: 5px 12px 7px 14px;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            font-family: Lato;
            box-shadow: -1px 1px 1px color(shadow4), -2px 2px 4px color(shadow2);

            &.green {
              color: white;
              background-color: color(green);
              border-top-color: shade(green, 20);
              border-right-color: shade(green, 20);
              text-shadow: -1px 1px 3px shade(green, 50);
            }

            .fas {
              margin-right: 10px;
            }
          }
        }

        .inner-manage-block {
          .dropdown-menu {
            left: -26ch !important;
            min-width: 300px;
            border-radius: 16px 0 16px 16px;
            z-index: 30;
          }
        }

        .inner-author-block {
          position: relative;
          z-index: 1;

          img {
            width: 40px;
            height: 40px;
            border-radius: 6px;
          }

          .slider-author-text {
            font-size: 1.1vw;
            font-weight: 450;
            color: #777777;

            @include respond-to(medium) {
              font-size: 0.65vw;
            }

          }

          .slider-author-text-wrap {
            line-height: 1.5vw;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            padding-bottom: 5px;
            padding-top: 5px;

            @include respond-to(medium) {
              line-height: 15px;
            }
          }


          @include respond-to(medium) {
            font-size: 0.65vw;
          }
        }
      }
    }
  }
}