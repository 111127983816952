@charset 'utf-8';

.container-admin {
	position: fixed;
	top:0;
    padding: 0 15px;
    background-color: color(blue);
    z-index: 1028;
    box-shadow: shadow(box);

    @include respond-to(medium) {
        margin-top: 0;
    }

    .admin-title {
        display: none;

        @include respond-to(medium) {
            display: block;
            font: font(title-6);
            color: white;
            line-height: 22px;
            background-color: color(blue);
            padding: 0 8px;
            margin-bottom: -19px;
            border-radius: 0 0 4px 4px;
            box-shadow: shadow(box);
        }
    }

    .admin-actions {

        > a,
        > .button,
        > button,
        > .btn,
        > btn,
        > div.admin-icon {
            display: block;
            text-align: center;
            width: 100%;
            color: color(blue);
            background-color: tint(nav-bg, 90);
            border-left: 1px solid color(blue);
            border-right: 1px solid color(blue);
            border-bottom: 1px solid color(blue);
            border-top: 1px solid color(blue);
            border-radius: 4px;
            font: font(admin-link);
            padding: 10px 10px;
            min-width: 40px;
            box-shadow: shadow(box);

            &.active {
                background-color: color(blue);
                border-color: color(blue);
                color: tint(nav-bg, 80);
            }

            &.disabled {
                color: color(inactive);
                background-color: tint(inactive, 50);          
            }

            &:hover {
                color: tint(nav-bg, 100) !important;
                background-color: color(blue) !important;
                border-color: color(blue) !important;
                text-decoration: none;

                &.disabled {
                    color: tint(inactive,70) !important;
                    background-color: tint(inactive, 30) !important;          
                }

                &.active {
                    background-color: tint(nav-bg, 100);
                    color: color(blue);
                }
            }

            @include respond-to(medium) {
                width: auto;
                float: right;
                margin-left: 2px;
                margin-bottom: -20px;
                margin-top: 1px;
                padding: 0 8px;
                border-radius: 0 0 4px 4px;

                &.active {
                    margin-top: 5px;
                    margin-bottom: -24px;
                }
            }
        }
    }
}
