@charset 'utf-8';

.group-select-set {

	&.select-from-list {

		.custom-select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}    
	}
}

.group-select-add {

	.input-group.select-from-list {

		.custom-select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background-color: tint(silver,10);
			border-color: tint(silver,10);
			color: shade(silver, 70);
			font-weight: 300;
		}
	}

	.input-group.add-to-list {
		display: none;

		input {
			display: none;
			background-color: tint(silver,10);
			border-color: tint(silver,10);
			&.form-control-plaintext {
				padding-left: 0.75rem;
				padding-right: 0.75rem;
			}
		}
	}

	.input-group-append {
		display: -ms-flexbox;
		display: flex;
		margin-left: -1px;

		.btn {
			box-sizing: border-box;
			border-top-right-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			position: relative;
			z-index: 2;
			cursor: pointer;
			padding: .375rem .75rem;
			font-size: 1rem;
			line-height: 1.5;
			webkit-appearance: button;

			&.in {
				border-radius: 0;
			}

			&.btn-outline-secondary.btn-to-list {
				color: color(blue);
				border-color: tint(silver,10);
				background-color: tint(silver,10);

				&:hover {
					background-color: shade(silver,5);
				}

				&:disabled {
					color: shade(silver, 10);

					&:hover {
						color: shade(silver, 10);
						background-color: tint(silver,5);
						cursor: default;
					}
				}
			}

			&.btn-outline-secondary.btn-remove-list {
				color: color(red);
				border-color: tint(silver,10);
				background-color: tint(silver,10);
				display: none;

				&:hover {
					background-color: shade(silver,5);
				}
			}

			&.btn-outline-secondary.btn-edit-list {
				color: color(green);
				border-color: tint(silver,10);
				background-color: tint(silver,10);
				display: none;

				&:hover {
					background-color: shade(silver,5);
				}
			}

			&.btn-outline-secondary.btn-action-list {
				color: color(green);
				border-color: tint(silver,10);
				background-color: tint(silver,10);

				&:hover {
					background-color: shade(silver,5);
				}
			}

			&.btn-outline-secondary.btn-cancel-to-list {
				border-color: tint(silver,10);
				background-color: tint(silver,10);
			}
		}
	}             
}

.form-row {
	
	.form-group {
		
		.note-editor {
			border-color: tint(silver,10);
			border-radius: 8px;
			
			.note-toolbar{
				background-color: color(silver);
				border-bottom: 1px solid shade(silver, 3);
			}

		}
		
		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="url"],
		textarea,
		.note-editable {
			color: shade(silver, 70);
			border-color: tint(silver, 10);
			background-color: tint(silver, 10);
		}	
	}
	
}