[data-load] {
  width: 5em;
  height: 5em;
  border-radius: calc(5 / 16 * 1rem);
  position: relative;
  cursor: wait;
  overflow: clip;
  contain: content;
}

[data-load="loaded"] {
  cursor: auto;
}

[data-load]::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  aspect-ratio: 1 / 1;
  background-color: color(primary);
}

[data-load="loaded"]::before {
  animation: explode 0.2s linear;
}

[data-load="loaded"]::after {
  display: none;
}

[data-load="loading"]::after {
  content: "";
  position: absolute;
  left: calc(50% - (0.75em / 2));
  top: calc(50% - (0.75em / 2));
  width: 0.75em;
  height: 0.75em;
  background-color: color(primary);
  border-radius: 50%;
  display: block;
  animation: wiggle 0.3s linear infinite alternate;
}

@keyframes wiggle {
  0%,
  20% {
    transform: translatex(-1em) scalex(1);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translatex(0) scalex(2);
    animation-timing-function: ease-out;
  }

  80%,
  100% {
    transform: translatex(1em) scalex(1);
  }
}

@keyframes explode {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 0.2;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}