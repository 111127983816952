@charset 'utf-8';

/* top menu */

#top-nav {
  background-color: color(none);
  height: 40px;
  padding: 0 16px;

  .navbar-toggler {
    color: white;
    background-color: color(blue);
    padding: 0.10rem 0.40rem;
    margin-top: 8px;
    
    @include respond-to(small) {
        margin-top: 15px;
    }
  }

  .navbar-social {
    font-size: 0;
        
    >a {
      font: font(top-link);
      display: inline-block;
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 0;
      color: color(blue);
      background-color:  color(nav-bg);
      border-radius: 4px;
      margin: 0 2px;
      
      &:hover {
        color: color(green);
      }
/*
      >img {
        max-width: 16px;
        max-height: 16px;
      }
*/
    }
  }

  .navbar-top{
    .navbar-nav {
      background-color: color(blue-light);

      @include respond-to(medium) {
        background-color: transparent;
      }

      .nav-item {
        margin-bottom: 2px;
        margin-top: 2px;

        a {
          background: color(nav-bg);
          color: shade(blue,20);
          font: font(menu-link);
          padding-left: 16px;
          padding-right: 16px;
          border: 1px solid tint(blue, 50);

          .d-hover-inline-block {
            display: none;
          }
          
          @include respond-to(medium) {
            margin: 2px;
            padding: 0;
            text-align: center;
            width: 40px;
            background-color: color(nav-bg);
            border-radius: 4px;
            border: none;
            color: color(blue);
            font: font(top-link);
            
            img {
              height: 16px;
            }
          }

          &:hover{
            border: 1px solid color(blue);
            color: shade(blue,50);
            
            @include respond-to(medium) {
              color: color(green);
            }

            .d-hover-inline-block {
              display: inline-block;
            }

            .d-hover-none {
              display: none;
            }

            @include respond-to(medium) {
              border: none;
              /*color: shade(blue, 50)*/
            }
          }
        }

        &.active {
          .nav-link {
            color: color(green);
            /*background-color: color(blue);*/
            /*border: 1px solid tint(green, 50);*/
            /*border-radius: 4px;*/
            .menu-icon {
              .d-hover-none {display: none;}
              .d-hover-inline-block {display: inline-block;}
            }
          }
        }

        //.dropdown-menu {
        //  background-color: transparent;
        //  border: none;
        //  padding: 0 16px;
        //
        //
        //  &.right-0 {
        //    left: auto;
        //    right: 0;
        //  }
        //
        //  .dropdown-header {
        //    padding: 0.2em 16px;
        //    background-color: color(nav-bg);
        //  }
        //
        //  .dropdown-item {
        //    display: block;
        //    background: color(nav-bg);
        //    color: shade(blue,20);
        //    padding: 0.5rem 16px;
        //    border: 1px solid tint(blue, 50);
        //    margin-bottom: 2px;
        //    margin-top: 2px;
        //
        //    &:hover{
        //      border: 1px solid color(blue);
        //      color: shade(blue,50);
        //    }
        //
        //    @include respond-to(medium) {
        //      width: 100%;
        //      text-align: left;
        //      font-size: 1rem;
        //      line-height: 1.5rem;
        //      padding: 0.3rem 15px;
        //    }
        //  }
        //
        //  .dropdown-divider{
        //    border-top-color: tint(blue, 80);
        //    margin-bottom: 2px;
        //    margin-top: 2px;
        //  }
        //}

      }
    }
  }
}