@charset 'utf-8';

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: white;
    box-sizing: border-box;
    font: font(base);
    color: color(base);

    @each $key, $value in $fonts {
        .h-#{$key} {
            font: $value;
        }
    }

    @each $key, $value in $colors {
        .h-#{$key} {
            color: $value;
        }
		.title-#{$key} {
            color: $value;
        }
        .btn-#{$key},
        .message-#{$key} {
            background-color: $value;
            border-color: shade($key, 15);
        }
        .btn-#{$key}:hover,
			.message-#{$key}:hover {
            background-color: tint($key, 15);
        }
        .text-#{$key},
        .link-#{$key} {
            color: $value;
        }
        .text-#{$key}:hover,
			.link-#{$key}:hover {
            color: tint($key, 15);
        }
		.bg-#{$key} {
			background-color: $value;
		}
    }

	.container {
		padding-right: 80px;
		padding-left: 80px;

		@include respond-to(medium) {
			padding-right: 55px;
			padding-left: 55px;
		}

		@include respond-to(large) {
			padding-right: 15px;
			padding-left: 15px;
		}

	}
	
	.rounded-10 {
		border-radius: 10px;
	}

	.bigger {
		font-size: 1.2em;
	}

	code {
		color: black;
	}

	i.fa.fa-edit.edit-field,
	.input-group.edited .fa {
		font-size: 10px;
		cursor: pointer;
		color: color(blue);

		&.fa-check {
			color: color(green);
			padding: 0 3px 0 6px;
		}
		&.fa-times {
			color: color(red);
			padding: 0 3px;
		}
	}
	i.fa.fa-edit.edit-field {
		float: right;
	}

	.white-space-no {
		white-space: nowrap;
	}

	.btn-link {
		background-color: transparent;
		border: none;
		text-decoration: none;
		color: color(link);

		&:hover {
			background-color: transparent;
			border: none;
			text-decoration: underline;
			color: shade(link, 20);
		}
	}

	.red {
        background-color: color(red) !important;
		color:white;
    }

    a {
        color: color(link);

        &:hover {
            color: color(active);
            text-decoration: none;
        }
    }

    form input.error,
    form select.error,
    form textarea.error {
        border-color: color(red);
    }

    .row-developer {
        margin-top: 10px;
        margin-bottom: 10px;

        button {
            color: red;
            font-size: 0.8rem;
            line-height: 0.95rem;
            padding: 0.15rem 0.4rem;
            opacity: .35;

            &:hover {
                color: color(yellow);
            }
        }

        #collapseDev {
            border: 1px dotted color(red);
            border-radius: 10px;
            background-color: color(yellow);
            margin: 10px -10px;
            padding: 10px;
        }
    }

    table {

        tr {

            th {
                color: color(dark);
                font-weight: 400;
            }

            td {
                color: color(base);
            }
        }
    }
	// @Deprecated
	//.container-fluid {
	//
	//	&.silver {
	//
	//		&:before {
	//			content: '';
	//			background-color: color(fluid-silver);
	//			width: 100%;
	//			position: absolute;
	//			display: block;
	//			height: 100%;
	//		}
	//
	//		&.push {
	//			&:before {
	//				margin-top: 50px;
	//			}
	//		}
	//	}
	//}

	.main-background {

		&.silver {
			&:before {
				content: '';
				background-color: color(fluid-silver);
				width: 100%;
				position: absolute;
				display: block;
				height: 50%;
			}

			&.push {
				&:before {
					bottom: -20px;
				}
			}
		}
	}

	.background-color {
		&-silver {
			background-color: color(fluid-silver);
		}
	}

    .clear {
        overflow: auto;

        &:after {
            content: "\a0";
            display: block;
            width: 100%;
            clear: both;
            font-size: 0;
            line-height: 0;
        }
    }

	.clear-fields {
		cursor: pointer;
	}

	>.width {
		position: fixed;
		width: auto;
		top: 2;
		left: 4;
		padding: 0;
		font-size: 12px;
		line-height: 12px;
		color:  color(none);
	}

	.table {

		tbody {
			width: 100%;

			tr {
				width: 100%;
			}
		}
	}

	.btn-success {
		background-color: color(green);
		border-color: color(green);
		text-shadow: 0 0 1px tint(green, 80);

		&:hover {
			background-color: shade(green, 10);
			border-color: shade(green, 10);
		}
	}

	.btn-primary {
		background-color: color(blue);
		border-color: color(blue);
		text-shadow: 0 0 1px tint(blue, 80);

		&:hover {
			background-color: shade(blue, 10);
			border-color: shade(blue, 10);
		}
	}

	.btn-danger {
		background-color: color(red);
		border-color: color(red);
		text-shadow: 0 0 1px tint(red, 80);

		&:hover {
			background-color: shade(red, 10);
			border-color: shade(red, 10);
		}
	}

	button.btn.btn-block {
		margin: 0;
	}

	.z-20 { position: relative; z-index:20; }
	.z-19 { position: relative; z-index:19; }
	.z-18 { position: relative; z-index:18; }
	.z-17 { position: relative; z-index:17; }
	.z-16 { position: relative; z-index:16; }
	.z-15 { position: relative; z-index:15; }
	.z-14 { position: relative; z-index:14; }
	.z-13 { position: relative; z-index:13; }
	.z-12 { position: relative; z-index:12; }
	.z-11 { position: relative; z-index:11; }
	.z-10 { position: relative; z-index:10; }
	.z-9  { position: relative; z-index: 9; }
	.z-8  { position: relative; z-index: 8; }
	.z-7  { position: relative; z-index: 7; }
	.z-6  { position: relative; z-index: 6; }
	.z-5  { position: relative; z-index: 5; }
	.z-4  { position: relative; z-index: 4; }
	.z-3  { position: relative; z-index: 3; }
	.z-2  { position: relative; z-index: 2; }
	.z-1  { position: relative; z-index: 1; }

	.tabs {
		font-size: 0;
		line-height: 0;

		.nav-tabs {
			list-style: none;
			margin: 0;
			padding: 6px 0 0 0;
			position: relative;
			z-index: 2;
			font-size: 0;
			line-height: 0;

			.nav-item {
				display: block;
				border: 1px solid color(modal-border);
				border-radius: 85px;
				padding: 0;
				font-size: 0;
				line-height: 0;
				background-color: color(modal-bg-inactive);
				box-shadow: 3px -1px 3px color(modal-shadow);

				@include respond-to(medium) {
					border-radius: 12px 12px 0 0;
					display: inline-block;
					text-align: center;
					margin: 0 0 0 -5px;
				}

				&:first-of-type {
					margin-left: 0;
				}

				&.active {
					position: relative;
					z-index: 100;
					background-color: color(modal-bg);
					border-bottom-color: color(modal-border);

					@include respond-to(medium) {
						border-bottom-color: color(modal-bg);
					}
				}

				&:hover {
					background-color: color(modal-bg);
				}

				a {
					display: inline-block;
					margin: 0;
					padding: 5px 20px 0;
					color: shade(blue,20);
					font-size: 15px;
					line-height: 35px;
					background-color: transparent;
					border: none;

					&.active {
						color: shade(green,15);
						background-color: transparent;
						padding: 0 20px;
						line-height: 40px;
						border: none;
					}
				}
			}
		}

		.tab-content {
			position: relative;
			z-index: 1;
			border: 1px solid color(modal-border);
			border-radius: 0 5px 5px 5px;
			background-color: color(modal-bg);
			padding: 40px;
			top: -1px;
			margin-top: -1px;
			font-size: 1rem;
			line-height: 1.25;
			box-shadow: 5px -2px 5px color(modal-shadow);

			@include respond-to(medium) {
				border-radius: 5px;
				padding:15px;
			}
		}
	}

	.custom-select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.custom-radio {
		/*padding-left: 30px;*/
	}

	.btn-primary:not(:disabled):not(.disabled):active,
		.btn-primary:not(:disabled):not(.disabled).active,
		.show > .btn-primary.dropdown-toggle {
		border-color: color(blue);
		background-color: color(blue);
	}

	.admin-actions {
		background-color: color(admin-actions);
		position: absolute;
		white-space: nowrap;
		padding: 0;
		margin: 0;
		font-size: 0;
		line-height: 0;
		top:1px;
		right: 1px;
		left: 1px;
		height: 24px;
		z-index: 99;
	}

	.admin-bar-info {
		color: rgba(0,0,0,0.35);
		font-size: 10px;
		line-height: 24px;
	}

	.btn-admin {
		padding: 0.2rem 0.75rem;
	}
	.btn-admin,
	.admin-action.btn, 
	.toggle-edit-mode {
		background-color: shade(silver,5);
		color: color(4);

		&.btn-danger {
			background-color: tint(red,10);
			color: white;
		}
	}

	.admin-small-button {
		background-color: shade(silver,5);
		color: color(4);
		cursor: pointer;
		padding: 0;	margin: 1px;
		font-size: 12px; line-height: 21px;
		width: 21px; height: 21px;
		text-align: center;
		border-radius: 50%;
		border-color: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		z-index: 10;
	}

	.admin-small-button {
		background-color: shade(silver,5);
		color: color(4);
		cursor: pointer;
		padding: 0;	margin: 1px;
		font-size: 12px; line-height: 21px;
		width: 21px; height: 21px;
		text-align: center;
		border-radius: 50%;
		border-color: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		z-index: 10;
	}
	.btn-admin:hover,
		.toggle-edit-mode:hover,
		.admin-action.btn:hover,
		.admin-small-button:hover {
		background-color: shade(silver,10);
		color: color(3);

		&.btn-danger {
			background-color: shade(red,5);
			color: white;
		}
	}
	.btn-admin.active,
	.toggle-edit-mode.active,
	.admin-small-button.active {
		background-color: tint(silver, 80);
		border-color: color(1);
		color: color(1);
	}

	.empty-row {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;

		>.clear2up {
			position: absolute;
			top: 3px;
			right: -18px;
			font-weight: bold;
			color: color(red);
			font-size: 14px;
			line-height: 14px;
			margin: 0;
			padding: 3px 6px;
			border-radius: 50%;
			border: 1px solid shade(light,10);
			background-color: color(light);
		}

		>.col {

			margin-top: 15px;
			margin-bottom: 15px;
		}
	}

	.paginator {

		.page-link {
			border: none;
			background-color: transparent;
			color: inherit;
			margin: 0;
			padding: 0;
		}

	}

	.pagination {
		border: none!important;

		.page-item {
			background-color: transparent;
			cursor: pointer;

			.page-link {
				color: black;
				//color: color(red);
				font-size: 12px;
				font-weight: bold;

				&:hover{
					color: black;
				}
			}

			&.disabled{
				//background-color: white;
				cursor: default;

				.page-link {
					color: color(inactive);
				}
			}

			&.active{
				background-color: color(red);
				cursor: default;
				.page-link {
					//border-color: color(red);
					//border-radius: 5px;
					color: white;
				}
			}

			&.hover-effect {
				&:hover {
					background-color: color(red-effect);
				}
			}

		}
	}

	.instruction{
		font-size: 12px;
		text-align: left;
	}

	.form-control.bg-fluid-silver:disabled, .form-control.bg-fluid-silver[readonly]{
		background-color: color(fluid-silver);
	}

	.note-editor {

		.note-toolbar {

			.note-btn-group {

				border-left: 1px solid shade(silver,10);
				padding-left: 5px;

				&:first-of-type {
					border-left: none;
					padding-left: 0;
				}
			}
		}
	}

	.finder-data {

		.row-search {

			.input-group {

				&.regions-group {

					#regiony {

						text-align: justify;
						margin-bottom: 30px;
						font-size: 0;

						span {
							font-weight: lighter;
							font-size: 22px;
							letter-spacing: -1px;
							color: color(base);
							cursor: pointer;

							&.text-success {
								font-weight: normal;
								letter-spacing: -1.5px;
							}
						}
					}
				}

				&.finder-group {

					input {
						border-radius: 12px;
						border-width: 0;
						text-align: center;
					}

					.color {

						&.primary {
							background-color: white;
						}

						&.secondary {
							background-color: #f7f7f7;
						}
					}


				}
			}
		}

		.row-msg {

			.msg-area {

				.msg {
					display: inline-block;
					font-size: 12px;
				}

				.clear-msg {
					cursor: pointer;
					margin-left: 10px;
					color: color(red);
				}
			}
		}

		.row-tags {

			.tags-area {

				.tag {
					display: inline-block;
					white-space: nowrap;
					color: black;
					margin: 4px;
					transition: opacity 3s ease-in-out;
					-webkit-transition: opacity 3s ease-in-out;
					-moz-transition: opacity 3s ease-in-out;
					-ms-transition: opacity 3s ease-in-out;
					-o-transition: opacity 3s ease-in-out;
					opacity: 1;

					.hide {
						opacity:0;
					}

					.card {
						border-radius: 9px;
						border-width: 2;
						border-color: tint(blue,50);

						.card-body {
							padding: 0.35rem 0.65rem;

							input.form-control-plaintext {
								background-color: transparent;
								color: color(blue);

								&:focus {
									outline: none;
								}
							}
						}
					}

					.form-control-plaintext {
						display: inline-block;
						width: auto;
						min-width: 15px;
						max-width: 170px;
						appearance: none;
						-webkit-appearance: none;
						-moz-appearance: none;
						background-color: transparent;
						border: 0;
						font-size: 16px;
						line-height: 16px;
						margin: 0;
						padding: 0;
					}
				}

				.clear-tag {
					cursor: pointer;
					margin-left: 10px;
					color: color(red);
				}
			}
		}

	}

	.admin-panel {
		//
		//top: 0;
		//width: auto;
		//margin: o auto;
		//
		//

		.main-menu {
			border-radius: 20px; 
			background-color: color(admin-bg); 
			border: none;

			.card-body {
				background: transparent;
			}
		}

		.main-menu-absolute {
			position: fixed;
			right: 35px;
			bottom: 20px;
			z-index: 1000;

			&.push-1x {
				bottom: 150px;
			}

			.card {
				border-radius: 20px;
				background-color: color(admin-bg);
				border: none;

				.card-body {
					background: transparent;
				}
			}
		}

	}

	//Dropdown - New
	.dropdown-menu {
		border-radius: 0 16px 16px 16px;
		z-index: 30;

		&.small {
			min-width: 150px;

			&.left {
				//Nadpisanie bootstrap'a
				left: -150px!important;
			}
		}

		&.medium {
			min-width: 300px;

			&.left {
				//Nadpisanie bootstrap'a
				left: -300px!important;
			}
		}

		&.big {
			min-width: 450px;

			&.left {
				//Nadpisanie bootstrap'a
				left: -450px!important;
			}
		}

		&.left {
			border-radius: 16px 0 16px 16px;
		}
	}

	// Animacje wejścia/wyjścia
	// BackOutUp @deprecated
	// BackOutDown @deprecated
	// BackInDown @deprecated
	// BackInUp @deprecated

	.back-out-up {
		-webkit-animation: backOutUp 1s;
		animation: backOutUp 1s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
	}

	.back-in-down {
		-webkit-animation: backInDown 0.5s;
		animation: backInDown 0.5s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	.back-in-up {
		-webkit-animation: backInUp 0.5s;
		animation: backInUp 0.5s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
	}

	.back-out-down {
		-webkit-animation: backOutDown 0.5s;
		animation: backOutDown 0.5s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
	}
}

.input-group {

	.input-group-append {

		.fa-times {

			color: color(red);
		}
	}
}

.clear-msg {
	display: inline-block;
	cursor: pointer;
	color: red;
}

.card-header .fa {
	transition: .3s transform ease-in-out;
}
.card-header .fa-chevron-left {
	color:color(base);
}
.card-header [aria-expanded="true"] .fa-chevron-left {
	transform: rotate(-90deg);
}

.card-error-img {
	font-size: 9px;
	color: #666;
	font-family: 'Open Sans';
}

.tooltip {
	.tooltip-inner {
		span {
			white-space: nowrap;
			color: lime;
		}
		code {
			color: color(yellow);
			white-space: nowrap;
		}
		a{
			white-space: nowrap;
			color: color(link);
		}
	}
}

.form-order {
	display: inline-block;
	background-color: shade(silver,10);
	border-radius: 10px;
	margin: 0;
	padding: 0;
	width: 70px;
	max-width: 70px;
	
	label {
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
	
	input[type="number"] {
		line-height: 24px;
		height: 24px;
		padding: 0 0 0 5px;
		width: 94%;
		max-width: 94%;
		border: none;
		margin: 0 3%;
		box-shadow: rgba(0,0,0,0.2) 1px 1px 3px;
	}
}

.input-group {
	
	.input-group-prepend {
		border-color: tint(silver,10);
		background-color: shade(silver,1.5);

		.input-group-text {
			color: shade(silver, 50);
			font-style: normal;
			border: none;
			background-color: transparent;
		}
	}
	input {
		color: shade(silver, 70);
		border-color: tint(silver,10);
		background-color: tint(silver,10);
	}

	.input-group-append {
		border-color: tint(silver,10);
		background-color: shade(silver,1.5);
		
		.input-group-text {
			color: shade(silver, 50);
			font-style: normal;
			border: none;
			background-color: transparent;
		}
	}

}

/* pulsating */

.pulsating-circle {
	position: absolute;
	left: 20px;
	top: 5px;
	transform: translateX(-50%) translateY(-50%);
	width: 22px;
	height: 22px;

	&:before {
		content: "";
		position: relative;
		display: block;
		width: 200%;
		height: 200%;
		box-sizing: border-box;
		margin-left: -50%;
		margin-top: -50%;
		border-radius: 45px;
		background-color: #01a4e9;
		-webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;

	}

	&.style-1 {
		&:before {
			background-color: #e76464;

		}

		&:after {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05%) inset, 0 0 3px 0 rgba(0, 0, 0, 0.05%);
			background-color: #e76464;
		}
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		display: block;

		width: 22px;
		height: 22px;

		background-color: white;
		border-radius: 15px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		-webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
		animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	}
}

@-webkit-keyframes pulse-ring {
	0% {transform: scale(0.33);}
	80%, 100% {opacity: 0;}
}

@keyframes pulse-ring {
	0% {transform: scale(0.33);}
	80%, 100% {opacity: 0;}
}
@-webkit-keyframes pulse-dot {
	0% {transform: scale(0.8);}
	50% {transform: scale(1);}
	100% {transform: scale(0.8);}
}
@keyframes pulse-dot {
	0% {transform: scale(0.8);}
	50% {transform: scale(1);}
	100% {transform: scale(0.8);}
}

/* pulsating end */