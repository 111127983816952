@charset 'utf-8';

/*
    Pozycjonowanie elementów
    @usage - 
        @include position(5) // all 4
        @include position(5,4) // vertical, horizontal
        @include position(5,4,3) // top, horizontal, bottom
        @include position(5,4,3,2) // top, right, bottom, left
*/
@mixin position($top, $right: $top, $bottom: $top, $left: $right) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
 }