@charset 'utf-8';

main {
    /*min-height: calc( 100vh - 150px );*/
    z-index: 2;

    .container-content {
        padding-top: 60px;
        padding-bottom: 30px;

    }

	.card-footer {
		
		.admin-action.btn {
			width: auto;
			height: auto;
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			border-width: 1px;
			
			&.btn-block {
				display: block;
				width: 100%;
			}
			
			&.btn-primary {
				color: white;
				background-color: color(blue);
			}
			
			&.btn-cancel {
				background-color: shade(silver, 15);
				color: black;
			}
		}
	}
	
    
    .dn {
        display: none;
    }

    .db {
        display: block;
    }

	.group-select-add{
		
		.input-group {
			padding-top: 0;
			padding-bottom: 0;
			
			label {
				padding: 8px 8px 8px 0 ;
				padding-bottom: 0;
			}
		}
	}
    
    .row-developer {
        text-align: left;
    }
    
    .form-group label span {
        white-space: nowrap;
    }
    
    .f-blue {
        color: color(blue);
    }
    
    .f-blue-background {
        background-color: color(blue)l
    }

}

