@charset "utf-8";

@import
'base/girds',     // xs:0, sm:576px (small), md:768px (medium), lg:992px (large), xl:1200px (big) (@include respond-to(big){})
'base/colors',    // color(<key>) | tint(<key>, <percent>) [rozjaśnienie] | shade(<key>, <percent>) [przyciemnienie]
'base/gradients', // gradient(<key>)
'base/shadows';   // shadow(<key>)

@import
'fonts/fonts';

.wt-card {
  border-radius: 0.8rem;
  border-width: 0;

  &.border-sm {
    border-radius: 0.4rem;
  }

  .max-lines {
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;

    &.size-1 {
      max-height: 1.8em;
      line-height: 1.8em;
    }

    &.size-2 {
      max-height: 3.6em;
      line-height: 1.8em;
    }

    &.size-3 {
      max-height: 5.4em;
      line-height: 1.8em;
    }

    &.size-4 {
      max-height: 7.2em;
      line-height: 1.8em;
    }

    &.size-5 {
      max-height: 9em;
      line-height: 1.8em;
    }

    &.size-6 {
      max-height: 10.8em;
      line-height: 1.8em;
    }

    &.size-7 {
      max-height: 12.6em;
      line-height: 1.8em;
    }
  }

  &.bg-inherit-dark-sm {
    background-color: rgba(0, 0, 0, .05%);
  }

  &.bg-inherit-dark {
    background-color: rgba(0, 0, 0, .15%);
  }

  &.bg-inherit-light {
    background-color: rgba(255, 255, 255, .3%);
  }

  //Emoji label
  .emoji-grid {
    display: flex;

    .emoji {
      position: relative;
      width: 50px;
      margin: 0 10px 10px 0;
      text-align: center;
      min-width: 36px;

      &.fontSize {
        span {
          display: inline-block;
          font-size: 26px;
        }
      }

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
        &.detectRetina {
          span {
            display: inline-block;
            letter-spacing: -0.34em;
          }
        }
      }
    }
  }

  input, textarea, .note-editor {
    border-width: 0;
    border-radius: 0.7rem;
  }

  &.bg-light {

    .note-editor.note-frame, .note-editor.note-airframe, select {
      border: 0;
      background-color: white!important; // important z faktu (select) do przebudowy
      border-radius: 0.7rem;
    }
  }


  &.wt-card-shadow {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }

  .img-thumbnail {
    z-index: 10;

    &.style-1 {
      padding: 0;
      border-width: 0;
      border-radius: 0.7rem;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    }

  }


}


