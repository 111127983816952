@charset 'utf-8';

/////////// FONTS ///////////
@font-face {
  font-family: 'NasalizationRg-Regular';
  font-style: normal;
  font-weight: 400; /*regular*/
  src: url('Typodermic/NasalizationRg-Regular.eot');
  src: 
    url('Typodermic/NasalizationRg-Regular.eot?#iefix') format('embedded-opentype'), 
    url('Typodermic/NasalizationRg-Regular.woff2') format('woff2'), 
    url('Typodermic/NasalizationRg-Regular.woff') format('woff'), 
    url('Typodermic/NasalizationRg-Regular.ttf') format('truetype')
  ;
}

//Podstawowe fonty strony

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: local('Poppins Thin'), local('Poppins-Thin'), url('Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: local('Poppins ThinItalic'), local('Poppins-ThinItalic'), url('Poppins/Poppins-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url('Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: local('Poppins ExtraLightItalic'), local('Poppins-ExtraLightItalic'), url('Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'), url('Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: local('Poppins LightItalic'), local('Poppins-LightItalic'), url('Poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url('Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: local('Poppins Italic'), local('Poppins-Italic'), url('Poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'), url('Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: local('Poppins MediumItalic'), local('Poppins-MediumItalic'), url('Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url('Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: local('Poppins SemiBoldItalic'), local('Poppins-SemiBoldItalic'), url('Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'), url('Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: local('Poppins BoldItalic'), local('Poppins-BoldItalic'), url('Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'), url('Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: local('Poppins ExtraBoldItalic'), local('Poppins-ExtraBoldItalic'), url('Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: local('Poppins Black'), local('Poppins-Black'), url('Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: local('Poppins BlackItalic'), local('Poppins-BlackItalic'), url('Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

//KONIEC podstawowych fontóœ strony


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300; /*light*/
  src: local('OpenSans Light'), local('OpenSans-Light'), url('Open_Sans/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300; /*light*/
  src: local('OpenSans LightItalic'), local('OpenSans-LightItalic'), url('Open_Sans/OpenSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400; /*regular*/
  src: local('OpenSans Regular'), local('OpenSans-Regular'), url('Open_Sans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400; /*regular*/
  src: local('OpenSans Italic'), local('OpenSans-Italic'), url('Open_Sans/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600; /*semi-bold*/
  src: local('OpenSans SemiBold'), local('OpenSans-SemiBold'), url('Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600; /*semi-bold*/
  src: local('OpenSans SemiBoldItalic'), local('OpenSans-SemiBoldItalic'), url('Open_Sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700; /*bold*/
  src: local('OpenSans Bold'), local('OpenSans-Bold'), url('Open_Sans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700; /*bold*/
  src: local('OpenSans BoldItalic'), local('OpenSans-BoldItalic'), url('Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800; /*extra-bold*/
  src: local('OpenSans ExtraBold'), local('OpenSans-ExtraBold'), url('Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800; /*extra-bold*/
  src: local('OpenSans ExtraBoldItalic'), local('OpenSans-ExtraBoldItalic'), url('Open_Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}
/*LATO*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100; /*thin*/
  src: local('Lato Hairline'), local('Lato-Hairline'), url('Lato/Lato-Hairline.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100; /*thin*/
  src: local('Lato HairlineItalic'), local('Lato-HairlineItalic'), url('Lato/Lato-HairlineItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300; /*light*/
  src: local('Lato Light'), local('Lato-Light'), url('Lato/Lato-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300; /*light*/
  src: local('Lato LightItalic'), local('Lato-LightItalic'), url('Lato/Lato-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400; /*regular*/
  src: local('Lato Regular'), local('Lato-Regular'), url('Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400; /*regular*/
  src: local('Lato Italic'), local('Lato-Italic'), url('Lato/Lato-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700; /*bold*/
  src: local('Lato Bold'), local('Lato-Bold'), url('Lato/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700; /*bold*/
  src: local('Lato BoldItalic'), local('Lato-BoldItalic'), url('Lato/Lato-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900; /*black*/
  src: local('Lato Black'), local('Lato-Black'), url('Lato/Lato-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900; /*black*/
  src: local('Lato BlackItalic'), local('Lato-BlackItalic'), url('Lato/Lato-BlackItalic.ttf') format('truetype');
}

/////////// MAP ///////////
/// https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/
/// all: [ <‘font-style’> ||<‘font-weight’>]? <‘font-size’> [ / <‘line-height’> ]? <‘font-family’> ] | caption | icon | menu | message-box | small-caption | status-bar
/// style: normal, italic, oblique
/// weight: see @font-face positions
/// size: xx-small | x-small | small | medium | large | x-large | xx-large | larger | smaller
/// how this map use with function "font"
/// .element-html {
///   font: font(content); // 'Open Sans'
/// }
$fonts: (
base: 300 13px/20px 'Poppins',
standard: 300 1em 'Poppins',
text: 300 1em 'Poppins',
// fonty nagłówków h jako klasa np.: .h-2
1: 600 2rem/1.4 'Poppins',
2: 600 1.75rem/1.35 'Poppins',
3: 600 1.5rem/1.3 'Poppins',
4: 600 1.25rem/1.25 'Poppins',
5: 600 1.15rem/1.2 'Poppins',
6: 600 1.05rem/1.15 'Poppins',
7: 600 1rem/1.15 'Lato',
// stop fonty nagłówków z klasą
title-1: 400 2rem/1.4 'Poppins',
title-2: 400 1.75rem/1.35 'Poppins',
title-3: 400 1.5rem/1.3 'Poppins',
title-4: 400 1.25rem/1.25 'Poppins',
title-5: 400 1.15rem/1.2 'Poppins',
title-6: 400 1rem/1.15 'Poppins',
name-company-font: 400 1em/1.35 'Poppins',
title-company-font: 700 1.5em/1.35 'Poppins',
lato: 1em 'Lato',
open: 1em 'Open Sans',
thin: 100 1em 'Poppins',
extralight: 200 1em 'Poppins',
light: 300 1em 'Poppins',
normal: 400 1em 'Poppins',
regular: 400 1em 'Poppins',
medium: 500 1em 'Poppins',
semi: 600 1em 'Poppins',
semibold: 600 1em 'Poppins',
bold: 700 1em 'Poppins',
extra: 800 1em 'Poppins',
extrabold: 800 1em 'Poppins',
black: 900 1em 'Poppins',
small: 300 0.7em/1.15 'Poppins',
link: 400 1em 'Poppins',
admin-link: 400 15px/23px 'Poppins',
top-link: 400 18px/40px 'Poppins',
menu-link: 400 14px/20px 'Poppins',
menu-default-link: 400 15px/35px 'Poppins',
title-page: 400 4.5vw/5vw 'Poppins',
title-page-mobile: 400 1.6rem/1.9rem 'Poppins',
subtitle-page: 400 1.4rem/1.2rem 'Poppins',
section-title: 400 1.5em/1.5em 'Poppins',
header:  300 1.2rem/1.5 'Poppins'
);
// Arek: //'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',

/////////////// FUNCTION ///////////////
/// Pobranie fontu z mapy fontów (przykład pod mapą)
@function font($key) {
  @if map-has-key($fonts, $key) {
    @return map-get($fonts, $key);
  }
  @warn "Unknown `#{$key}` in $fonts.";
  @return null;
}
