@charset 'utf-8';

/////////// GIRDS ///////////
/// bootstrap min-width: (xs: 0, sm: 576px (small), md: 768px (medium), lg: 992px (large), xl: 1200px (big))
/// how to use
/// .element-html {
///   width: 100%;
///   @include respond-to(big) {
///     width: 25%;
///     float: left;
///   }
/// }
/// result
/// .element-html {
///   width: 100%;
/// }
/// @media (min-width: 1200px) {
///   .element-html {
///     width: 25%;
///     float: left;
///   }
/// }

$breakpoints: (
        small: 576px,
        medium: 768px,
        large: 992px,
        big: 1200px,
);

/// Pobranie brealpoint z mapy i zastąpienie deklaracji mixina deklaracją media min-width
/// @access public
/// @param {String} $breakpoint - klucz breakpoint z mapy
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}
