@charset 'utf-8';

/// COLORS
/// Przykład:
/// div {
///   background-color: color(img-bg);
/// }
$colors: (
        base: rgb(90, 90, 90),
        text: rgb(90, 90, 90),
        violet: rgb(170, 46, 214),
        purple: rgb(170, 46, 214),
        light-gray: rgb(140, 140, 140),
        gray-dark: rgb(25, 25, 25),
        blue: rgb(0, 158, 229),
        blue-light: rgb(19, 169, 218),
        blue-very-light: rgb(15, 181, 236),
        blue-light-border: rgba(0, 158, 229, 0.10),
        dark-blue: rgb(0, 50, 71),
        blue-dark: rgb(0, 39, 110),
        orange: rgb(255, 64, 64),
        orange-light: rgb(255, 149, 101),
        orange-dark: rgb(255, 104, 99),
        yellow: rgb(240, 180, 54),
        green: rgb(87, 157, 86),
        green-trans: rgba(87, 157, 86, 0.25),
        danger: rgb(244, 78, 102),
        red: rgb(244, 78, 102),
		red-effect: rgba(244, 78, 102, 0.08),
        red-trans: rgba(220, 34, 44, 0.25),
        red-trans-small: rgba(220, 34, 44, 0.1),
        gold: rgb(200, 150, 100),
        white: rgb(255, 255, 255),
        white-light: rgba(255, 255, 255, 0.5),
        white-very-light: rgba(255, 255, 255, 0.75),
        dark: rgb(20, 30, 40),
        light: rgb(230, 240, 250),
        cancel: rgb(190, 200, 210),
        body: rgb(255, 255, 255),
        body-light: rgba(255, 255, 255, 0.5),
        content-bg: rgb(250, 252, 252),
        gray-content-bg: rgb(252, 252, 252),
        nav-bg: rgb(245, 247, 242),
        fluid-silver: rgb(247, 247, 247),
        silver: rgb(247, 247, 247),
        dark-silver: rgb(229, 229, 229),
        modal-bg: rgb(247, 247, 247),
        modal-border: rgb(183, 186, 183),
        modal-bg-inactive: rgb(224, 225, 224),
        modal-shadow: rgba(0, 0, 0, 0.05),
        img-bg: rgba(255, 255, 255, 0.9),
        img-border: rgb(222, 226, 230),
        green-pastel: rgb(54, 204, 104),
		active: rgb(0, 158, 229),
        inactive: rgb(140, 160, 150),
        accent: rgb(0, 229, 158),
        none: transparent,
        admin-actions: rgba(10, 20, 30, 0.15),
        link: rgb(54, 124, 194),
        1: rgb(40, 40, 40),
        2: rgb(50, 50, 50),
        3: rgb(60, 60, 60),
        4: rgb(70, 80, 80),
        5: rgb(80, 80, 80),
        6: rgb(90, 90, 90),
        7: rgb(100, 100, 100),
        row-search: rgba(0, 50, 71, 0.95),
        row-search-border: rgba(0, 50, 71, 0.85),
        footer: rgba(0, 158, 229, 0.25),
        footer-link: rgb(0, 158, 229),
        footer-bg: rgb(20, 30, 40),
        message-white: rgba(255, 255, 255, 0.75),
        shadow1: rgba(0, 0, 0, 0.1),
        shadow2: rgba(0, 0, 0, 0.2),
        shadow3: rgba(0, 0, 0, 0.3),
        shadow4: rgba(0, 0, 0, 0.4),
        shadow5: rgba(0, 0, 0, 0.5),
        shadow6: rgba(0, 0, 0, 0.6),
        shadow7: rgba(0, 0, 0, 0.7),
        shadow8: rgba(0, 0, 0, 0.8),
        shadow9: rgba(0, 0, 0, 0.9),
		admin-bg: rgba(240,240,240,0.85),
        primary: rgb(0, 158, 229),
);

/// Kolor
/// @access public
/// @param {String} $key - klucz koloru z mapy
@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }
  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

/// Rozjaśnienie
/// @access public
/// @param {String} $color-key - klucz koloru z mapy do rozjaśnienia
/// @param {Number} $percentage - procent pierwotnego `$color` w zwróconej wartości
/// @return {Color}
@function tint($color-key, $percentage) {
  @if map-has-key($colors, $color-key) {
    @return mix(white, map-get($colors, $color-key), $percentage);
  }
  @warn "Unknown `#{color-key}` in $colors.";
  @return null;
}

/// Przyciemnienie
/// @access public
/// @param {String} $color-key - klucz kolor z mapy do przyciemnienia
/// @param {Number} $percentage - procent pierwotnego `$color` w zwróconej wartości
/// @return {Color}
@function shade($color-key, $percentage) {
  @if map-has-key($colors, $color-key) {
    @return mix(black, map-get($colors, $color-key), $percentage);
  }
  @warn "Unknown `#{$color-key}` in $colors.";
  @return null;
}

