.hover {
  &-gap {
    margin: 0 10px;
  }

  /* Effect 0: Default */
  &-0:hover {
    -webkit-animation: hover 1200ms linear 2 alternate;
    animation: hover 1200ms linear 2 alternate;
  }

}

/* Effect 1: Brackets */
.cl-effect-1 a:hover {
  color: #EC3C62;
}

.cl-effect-1 a::before,
.cl-effect-1 a::after {
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}

.cl-effect-1 a::before {
  margin-right: 10px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
}

.cl-effect-1 a::after {
  margin-left: 10px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
}

.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after,
.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
  color: #EC3C62;
}

/* Effect 12: circle */
.cl-effect-12 a::before,
.cl-effect-12 a::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 50%;
  content: '';
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.2);
  -moz-transform: translateX(-50%) translateY(-50%) scale(0.2);
  transform: translateX(-50%) translateY(-50%) scale(0.2);
}

.cl-effect-12 a::after {
  width: 90px;
  height: 90px;
  border-width: 6px;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8);
  -moz-transform: translateX(-50%) translateY(-50%) scale(0.8);
  transform: translateX(-50%) translateY(-50%) scale(0.8);
}

.cl-effect-12 a:hover::before,
.cl-effect-12 a:hover::after,
.cl-effect-12 a:focus::before,
.cl-effect-12 a:focus::after {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  -moz-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
}

/* Effect 21: borders slight translate */
.cl-effect-21 {
  position: relative;
}

.cl-effect-21 a {
  padding: 10px;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.cl-effect-21 a::before,
.cl-effect-21 a::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #5a5a5a;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cl-effect-21 a::before {
  top: -4px;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cl-effect-21 a::after {
  bottom: -4px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}

.cl-effect-21 a:hover,
.cl-effect-21 a:focus {
  color: #000;
}

.cl-effect-21 a:hover::before,
.cl-effect-21 a:focus::before,
.cl-effect-21 a:hover::after,
.cl-effect-21 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}