//////////// okno modalne ////////////
// dla eventów
div.modal {
	background-color: rgba(0,0,0,0.8);
	box-sizing: border-box;
	z-index: 1000000000;
	max-width: 100%;
	
	* {
		box-sizing: border-box;
	}

	// akordeon menu
	.accordion {
		
		.card-header {
			padding: 0 0.75rem;

			&.extended {
				border-radius: 12px;

				.icon {
					height: 60px;
					width: 60px;

					.sign {
						line-height: 3.8rem;
					}

					&:before {
						content:"";
						position: absolute;
						@include position(0, 0, 0, 0);
						padding: 2px;
						border-radius: 8px;
						border-width: 2px;
						-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
						-webkit-mask-composite: destination-out;
					}

					&.primary {
						background: linear-gradient(220deg, color(blue-dark), color(blue-very-light));
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;

						&:before {
							background: linear-gradient(220deg, color(blue-dark), color(blue-very-light));
						}
					}
				}


			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.06);
			}
			
			h2 {
				line-height: 0;
				
				.btn-link {
					width: 100%;
					text-align: left;
					padding: 0 0.75rem;
					text-decoration: none;
					color: shade(silver,80);

					&:hover .collapse-title-primary {
						-webkit-animation-name: change;
						-webkit-animation-duration: 1s;
						animation-name: change;
						animation-duration: 1s;
						animation-fill-mode: forwards;
					}

					&:hover .collapse-title-secondary {
						-webkit-animation-name: change;
						-webkit-animation-duration: 1s;
						animation-name: change;
						animation-duration: 1s;
						animation-fill-mode: forwards;
					}

					.collapse-title {
						height: 30px;
						overflow: hidden;

						.collapse-title-primary, .collapse-title-secondary {
							font-size: 18px;
						}
					}

					@-webkit-keyframes opacity {
						0%, 100% {opacity:0;}
						50% {opacity:1;}
					}

					@-webkit-keyframes change {
						0% {transform:translate3d(0,0,0);}
						100% {transform:translate3d(0,-100%,0);}
					}

					@-o-keyframes opacity {
						0%, 100% {opacity:0;}
						50% {opacity:1;}
					}

					@-o-keyframes change {
						0% {transform:translate3d(0,0,0);}
						100% {transform:translate3d(0,-100%,0);}
					}

					@-moz-keyframes opacity {
						0%, 100% {opacity:0;}
						50% {opacity:1;}
					}

					@-moz-keyframes change {
						0% {transform:translate3d(0,0,0);}
						100% {transform:translate3d(0,-100%,0);}
					}

					@keyframes opacity {
						0%, 100% {opacity:0;}
						50% {opacity:1;}
					}

					@keyframes change {
						0% {transform:translate3d(0,0,0);}
						100% {transform:translate3d(0,-100%,0);}
					}
				}				
			}
		}
		
		.card-footer {
			padding: 0 1.25rem;
			background-color: white;
			border-top-color: transparent;
			
			.btn {
				margin: -1px 0;
				border-color: transparent;
				margin-top: 5px;
				margin-bottom: 10px;
			}
		}
	}

	div.modal-dialog{

		div.modal-content{
			border-radius: 15px;

			span {
				vertical-align: middle;
			}


			button.back, button.close-menu, button.close {
				position: absolute;
				top: 5px;
				background: shade(silver,10);
				color: shade(silver,90);
				border: 0;
				border-radius: 50%;
				font-size: 24px;
				font-weight: 300;
				text-align: center;
				width: 33px;
				height:33px;
				margin: 0;
				padding: 0;
				z-index: 10;
				opacity: .5;

				&:hover {
					opacity: 1;
				}
			}

			button.close-menu, button.close {
				right: 5px;
			}

			button.back {
				left: 5px;

				i {
					margin-right: 2px;
				}
			}




			div.modal-header {
				padding: 0.35rem 1rem;
			}

			div.modal-body{
				
				&.media-body {
					
					img,
					iframe, 
					video,
					.inner-body {
						border-radius: 15px;
					}
					
					.inner-body {
						background-color: black;
						text-align: center;
						height:575px; 
						max-height: 70vh; 
						width:auto;
						
						img {
							width: 100% \9;
							max-width: 100%;
							height: auto;
							max-height: 70vh;
						}
						
						.embed-responsive {
							max-height: 70vh;
						}
					}
				}
				
				&.content-body {
					
				}
			}
		}
	}
}

// dla galerii
#modal-gallery,
.modal-all-list {
    position: fixed;
    z-index: 1000000000;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
	margin: 0;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);

    /* The Close Button */
    .close {
        position: absolute;
        top: 10px;
        right: 25px;
        background: shade(silver,10);
		color: shade(silver,90);
		border: 0;
		border-radius: 50%;
		font-size: 30px;
		font-weight: 300;
		line-height: 33px;
		text-align: center;
		width: 33px;
		height:33px;
		margin: 0;
		padding: 0;
		z-index: 100;

        &:hover,
        &:focus {
			background-color: shade(silver, 20);
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .modal-content {
		display: flex; flex-direction: row;
        position: fixed; top: 10px; right: 0; bottom: calc( 10vh + 10px ); left: 0;
		align-items: center;
		justify-content: center;
        background-color: rgba(0,0,0,0.3);
        padding: 0;
		background-color: transparent; 
		border:0;
		max-width: 100%;
		margin: 0;

        .slide-image {
            /* Hide the slides by default */
            display: none;
            width: 100%;
	        max-width: 1600px;
			margin: auto;
            text-align: center;
			transition-timing-function: ease-in;
			transition: 2s;

            .slide-number {
                color: white;
                background-color: rgba(0,0,0,0.35);
                text-align: center;
                font-size: 16px;
                line-height: 25px;
                width: 50px;
                padding: 0;
                position: absolute;
                top: 0;
                left: 10px;
                border-radius: 5px;
            }

            img {
                width: auto;
                height: auto;
                margin: 0 auto;
                max-width: calc(100% - 10px);
                max-height: calc(90vh - 30px);
                border-radius: 15px; 
				box-shadow: 5px 20px 40px -35px rgba(0,0,0,0.99), 
							5px 40px 80px -40px rgba(0,0,0,0.99), 
							2px 4px 8px 0 rgba(0,0,0,0.85);
            }
        }

        /* Next & previous buttons */
        .prev,
        .next {
/*			display: none;*/
            cursor: pointer;
            position: absolute;
            top: calc( 50% - 25px );
            width: auto;
            padding: 15px;
            font-weight: bold;
            font-size: 20px;
			line-height: 20px;
			width: 50px;
			height: 50px;
			text-align: center;
            color: white;
            background-color: rgba(0,0,0,0.15);
            transition: 0.6s ease;
            border-radius: 50%;
            /*user-select: none;*/
            -webkit-user-select: none;

            &:hover{
                background-color: rgba(0, 0, 0, 0.65);
            }
			
/*			@include respond-to(small){
				display: block;
			}*/
        }
		.prev {
            left: 10px;
        }
        .next {
            right: 10px;
        }

        .slide-name-box {
            text-align: center;
            padding: 0;
            color: white;
            font-size: 16px;
            line-height: 40px;

            .slide-name {
                /* Hide the slides by default */
                display: none;
                margin: 0 0 10px;
                padding: 2px;
            }
        }
    }

	.container-thumbs {
		margin: 0; padding: 0; 
		position: fixed; bottom: 0; left: 0; right: 0; 
		width: 100%; min-width: 100%; max-width: 100%; height: auto; max-height: 10vh;
		font-size: 0; line-height: 0; 
		box-shadow: 0 -20px 40px -35px rgba(0,0,0,0.4), 0 -5px 10px 0 rgba(0,0,0,0.1);

		.slide-thumb-box {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0; line-height: 0;
			width: 100%; height: 10vh; min-height: 100%;
			box-sizing: border-box;
			margin: auto; padding: 5px 10px;
			background-color: rgba(0,0,0,0.35);
			
			.slide-thumb {
				flex-direction: row;
				width: auto;
				height: calc( 10vh - 10px );
				margin: 0; padding: 2px; 
				border-radius: 10px; 
				opacity: 0.65;

				&.active {
					opacity: 1;
					
					&:hover {
						opacity: 1;
					}
				}
				&:hover {
					opacity: 0.9;
				}
			}
		}
	}
}

/////////// miniatury ////////////
// style do miniatur eventów, 
// style do miniatur galerii.

[data-toggle="modal"]{
	cursor: pointer;
} // to wszędzie, nie tylko w galerii/evencie

.thumbs-container {

	.radius {
		border-radius: 15px;
		
		.radius {
			border-radius: 14px;
		}
	}
	
	// wszystkie miniatury
	.thumbs-row {

		* {
			box-sizing: border-box;
		}

		// wspólne dla miniatur
		.direction-side {

			.page-link {
				position: relative;
				display: block;
				padding: 0;
				width: 30px;
				height: 30px;
				border-radius: 15px;
				font-size: 14px;
				line-height: 28px;
				text-align: center;
				background-color: transparent;

				&.inactive {
					cursor: none;
					color: color(silver);
					border: 1px solid color(silver);
				}

				&.active {
					cursor: pointer;
					color: color(dark);
					border-color: color(dark);
				}
			}

		}

		.images-row {
			font-family: "Poppins", sans-serif;

			.btn-hover:hover {
				-webkit-animation: hover 1200ms linear 2 alternate;
				animation: hover 1200ms linear 2 alternate;
			}

			.paginator {
				&-between {
					width: 100%;
					top: 50%;
					font-size: 22px;
					padding-right: 0;
					padding-left: 0;
				}

				&-prior {
					left: -60px;
				}

				&-next {
					right: -60px;
				}

				&-button {
					cursor: pointer;
				}

				.inactive {
					-webkit-filter: contrast(10%) grayscale(100%) opacity(0.8);
					filter: contrast(10%) grayscale(100%) opacity(0.8);
				}
			}

			.slider {

				&.inner-block {
					position: relative;
					counter-increment: carousel-cell;
					padding: 0;

					.slider-item {
						
						&.col-6 {

							.slider-content {
								
								.introduction {
									min-height: 80px;
									color: color(inactive);
									text-align: justify;
								}
							}
						}
						
						&.col-4 {

							.slider-content {
								
								.introduction {
									min-height: 100px;
									line-height: 1.25;
									color: color(inactive);
									text-align: justify;
								}
							}
						}

						.slider-content {
							width: inherit;

							// Szablony graficzne
							// 0 - transparent
							// 1 - card
							&.theme {
								&-0 {

								}

								&-1 {
									position: relative;
									background-color: white;
									padding: 15px 15px;
									border-radius: 20px;
									margin: 7px 7px;

									.owl-author {
										img {
											border-radius: 50%;
											background-color: white;
											box-shadow: 0 .250rem .50rem rgba(black, .075);
										}
									}

									&:after {
										content: "";
										box-shadow: 0 .250rem .50rem rgba(black, .075);
										height: 100%;

										width: -moz-available;
										width: -webkit-fill-available;
										width: fill-available;

										position: absolute;
										top: 0;
										left: 0;
										padding: 25px;
										border-radius: inherit;
										overflow: visible !important;
									}
								}
							}

							.inner-header-block {
								top: -3.5%;
								flex-wrap: wrap;

								@include respond-to(medium) {
									flex-wrap: inherit;
								}

								.header-admin {
									position: relative;

									.dropdown {
										border: 5px solid;
										border-radius: 12px;

										&.white {
											background-color: white;
											border-color: white;
										}

										&.silver {
											background-color: color(silver);
											border-color: color(silver);
										}

									}

									&.right {
										@include respond-to(medium) {
											right: 15px;
										}
									}

									&.left {
										@include respond-to(medium) {
											position: absolute;
											top: auto;
											left: 15px;
										}
									}

									@include respond-to(medium) {
										position: absolute;
										top: auto;
									}

									.button-admin {
										padding: 0.7vw 2vw;
										font-size: 1.5vw;

										@include respond-to(medium) {
											font-size: 1vw;
										}

										@include respond-to(large) {
											font-size: inherit;
											padding: 0.35vw 1.2vw;
										}

										@include respond-to(big) {
											font-size: inherit;
											padding: 0.35vw 0.7vw;
										}
									}


								}

								.slider-header-label {
									position: relative;
									font-size: 1.4vw;
									right: 0;

									@include respond-to(medium) { // 690 w kolumnie,
										position: absolute;
										right: 15px;
										font-size: 0.6vw;
									}

									.label {
										letter-spacing: -0.25px;
										font-weight: 550;
										border-radius: 12px;
										border: 2px solid;
										padding: 0.5vw 1vw;

										@include respond-to(medium) { // 690 w kolumnie,
											border: 5px solid;
										}

										&.white {
											background-color: white;
											border-color: white;
										}

										&.silver {
											background-color: color(silver);
											border-color: color(silver);
										}

										.label-icon {
											border-radius: 50%;
											font-size: 20px;
											width: 30px;
											height: 30px;
											line-height: 30px;
											border: 1px solid color(shadow2);
											box-shadow: 0 0 1.5px 0 color(shadow2) inset, 0 0 1.5px 0 color(shadow2);
										}
									}
								}
							}

							.inner-title-block {
								
								.slider-title-text {
									font-size: 16px;
									font-weight: 500;
									margin-bottom: 0;
									height: 2.4em;
									text-align: justify;
								}

								.slider-title-text-wrap {
									max-height: 3.6em;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									overflow: hidden;
									display: -webkit-box;
								}
							}

							.inner-description-block {
								position: relative;
								z-index: 1;

								.slider-description-text {
									font-size: 12px;
									margin-bottom: 0 !important;
									height: 4.8em;

								}

								.slider-description-text-wrap {
									max-height: 5em;
									-webkit-line-clamp: 4;
									-webkit-box-orient: vertical;
									overflow: hidden;
									display: -webkit-box;
								}
							}
							
							.inner-thumb-block {
								/*background-color: black;*/
								cursor: pointer;
								width: 100%;

								.thumb {
									border-radius: 20px;
									overflow: hidden;

									img {
										border: none;
										background-color: transparent;
										padding: 0;
										background-position: center center;
										background-repeat: no-repeat;
										background-size: cover;
										margin: -10% 0;
										width: 100%;
									}
								}

								.thumb-16-9 {
									width: 100%;
									overflow: hidden;
									margin: 0;
									padding-top: 55%;
									position: relative;
									border-radius: 18px;

									img {
										position: absolute;
										top: 50%;
										left: 50%;
										width: 100%;
										transform: translate(-50%, -50%);
									}
								}

								.thumb-resource {
									border-radius: 18px;
									overflow: hidden;
									//height: 140.25px; //1,2,3,4,5 - 140.25 px, 6-X - 88 px;

									img {
										//width: 100%;
										//background-position: center center;
										//background-repeat: no-repeat;
										//background-size: cover;
										//background-color: transparent;
										border: none;
										background-color: transparent;
										padding: 0;
										background-position: center center;
										background-repeat: no-repeat;
										background-size: cover;
										//margin: -6% 0;
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}

								svg.absolute {
									opacity: 0.6;
								}

								.label {
									position: absolute;
									right: 0;
									top: 0;
									width: auto;
									max-width: calc(100% - 20px);
									border-width: 1px 1px 1px 1px;
									border-style: solid;
									border-radius: 0 2px 0 10px;
									border-left-color: color(white-light);
									border-bottom-color: color(white-light);
									padding: 5px 12px 7px 14px;
									font-size: 14px;
									line-height: 18px;
									font-weight: bold;
									font-family: Lato;
									box-shadow: -1px 1px 1px color(shadow4), -2px 2px 4px color(shadow2);

									&.green {
										color: white;
										background-color: color(green);
										border-top-color: shade(green, 20);
										border-right-color: shade(green, 20);
										text-shadow: -1px 1px 3px shade(green, 50);
									}

									.fas {
										margin-right: 10px;
									}
								}
							}

							.inner-author-block {

								img {
									display: block;
									width: 35px;
									height: 35px;
									margin-top: -2px;
									border-radius: 6px;
								}

								.slider-author-text {
									font-size: 1.1vw;
									font-weight: 450;
									color: #777777;

									@include respond-to(medium) {
										font-size: 0.75vw;
									}

								}

								.slider-author-text-wrap {
									line-height: 1.5vw;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									overflow: hidden;
									display: -webkit-box;
									padding-bottom: 5px;
									padding-top: 5px;

									@include respond-to(medium) {
										line-height: 15px;
									}
								}


								@include respond-to(medium) {
									font-size: 0.65vw;
								}
							}
						}
					}
				}
			}
		}

		// miniatury galerii
		&.gallery-thumbs {

			.images-row {

				.inner-thumb-block {
					height: 122px; 
					width:162px; 
					padding: 1px; 
				}
			}
		}

		// eventów now (2 szt) z napisami (na home tej klasy nie ma)
		&.event-now-thumbs {
			.images-row {
				.carousel {
					&.inner-block {
						@include respond-to(small) { // 510 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(medium) { // 690 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(large) { // 930 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(big) { // 1110 w kolumnie, 
							padding: 0 29px;
						}
						.flickity-viewport { // 450, 630, 870, 1052
							.carousel-cell {
								@include respond-to(small) { 
									width: 225px;
									padding: 0 5px;

									.inner-thumb-block {
										width: 215px;
										height: 120px;
									}
								}
								@include respond-to(medium) { 
									width: 315px;  
									padding: 0 10px;

									.inner-thumb-block {
										width: 295px;
										height: 165px;
									}
								}
								@include respond-to(large) { 
									width: 435px;  
									padding: 0 15px;

									.inner-thumb-block {
										width: 405px;
										height: 227px;
									}
								}
								@include respond-to(big) { 
									width: 526px; 
									padding: 0 13px;

									.inner-thumb-block {
										width: 500px;
										height: 280px;
									}
								}
							}
						}						
						.flickity-prev-next-button {
							@include respond-to(small){
								top: 70px;						
							}
							@include respond-to(medium){
								top: 91px;						
							}
							@include respond-to(large){
								top: 114px;						
							}
							@include respond-to(big){
								top: 140px;						
							}
						}
					}
				}
			}
		}

		// eventów current (3 szt) z napisami (na home tej klasy nie ma)
		&.event-cur-thumbs {
			.images-row {
				.carousel {
					&.inner-block {
						@include respond-to(small) { // 510 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(medium) { // 690 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(large) { // 930 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(big) { // 1110 w kolumnie, 
							padding: 0 30px;
						}
						.flickity-viewport { // 450, 630, 870, 1050
							.carousel-cell {
								@include respond-to(small) { 
									width: 150px;
									padding: 0 5px;

									.inner-thumb-block {
										width: 140px;
										height: 79px;
									}
								}
								@include respond-to(medium) { 
									width: 210px;
									padding: 0 10px;

									.inner-thumb-block {
										width: 190px;
										height: 107px;
									}
								}
								@include respond-to(large) { 
									width: 290px; 
									padding: 0 10px;

									.inner-thumb-block {
										width: 270px;
										height: 152px;
									}
								}
								@include respond-to(big) { 
									width: 350px; 
									padding: 0 10px;

									.inner-thumb-block {
										width: 330px;
										height: 185px;
									}
								}
							}
						}						
						.flickity-prev-next-button {
							@include respond-to(small){
								top: 40px;						
							}
							@include respond-to(medium){
								top: 54px;						
							}
							@include respond-to(large){
								top: 76px;						
							}
							@include respond-to(big){
								top: 93px;						
							}
						}
					}
				}
			}
		}

		// eventów old (3 szt) z napisami (na home tej klasy nie ma)
		&.event-old-thumbs {
			.images-row {
				.carousel {
					&.inner-block {
						@include respond-to(small) { // 510 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(medium) { // 690 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(large) { // 930 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(big) { // 1110 w kolumnie, 
							padding: 0 30px;
						}
						.flickity-viewport { // 450, 630, 870, 1050
							.carousel-cell {
								@include respond-to(small) { 
									width: 150px;
									padding: 0 5px;

									.inner-thumb-block {
										width: 140px;
										height: 79px;
									}
								}
								@include respond-to(medium) { 
									width: 210px;
									padding: 0 10px;

									.inner-thumb-block {
										width: 190px;
										height: 107px;
									}
								}
								@include respond-to(large) { 
									width: 290px; 
									padding: 0 10px;

									.inner-thumb-block {
										width: 270px;
										height: 152px;
									}
								}
								@include respond-to(big) { 
									width: 350px; 
									padding: 0 10px;

									.inner-thumb-block {
										width: 330px;
										height: 185px;
									}
								}
							}
						}						
						.flickity-prev-next-button {
							@include respond-to(small){
								top: 40px;						
							}
							@include respond-to(medium){
								top: 54px;						
							}
							@include respond-to(large){
								top: 76px;						
							}
							@include respond-to(big){
								top: 93px;						
							}
						}
					}
				}
			}
		}

		// eventów new (3 szt) z zapisami (nie jest skończony) (na home tej klasy nie ma)
		&.event-new-thumbs {
			.images-row {
				.carousel {
					&.inner-block {
						@include respond-to(big) { // 1170 w kolumnie, 
							padding: 0 30px;
						}
						.flickity-viewport {
							.carousel-cell {
								@include respond-to(big) { 
									width: 370px; // obrazki 350 x 3 = 1050
									padding: 0 10px;

									.inner-thumb-block {
										width: 350px;
										height: 197px;
									}
								}
							}
						}						
						.flickity-prev-next-button {
							@include respond-to(small){
								top: 140px;						
							}
							@include respond-to(medium){
								top: 140px;						
							}
							@include respond-to(large){
								top: 140px;						
							}
							@include respond-to(big){
								top: 140px;						
							}
						}
					}
				}
			}
		}
		
		// główny
		&.home-top-event {
			.images-row {

				.inner-block {

					.inner-thumb-block {

						.thumb {
							border-radius: 18px 18px 18px 0!important;
							background-color: transparent;
							max-width: 100%;
							box-shadow: 0 120px 100px -90px rgba(0,0,0,0.02), 0 100px 90px -80px rgba(0,0,0,0.08), 0 50px 40px -30px rgba(0,0,0,0.05), 10px 30px 22px 0 rgba(0,0,0,0.03);

							@include respond-to(small){
								border-radius: 35px 35px 35px 0!important;
							}
						}

						.theme {
							display: flex;
							&-wtp {
								display: grid;

								.svg {
									display: compact;

									&-up {
										position: absolute; z-index: 0;
										right:-30px; top:-20px;
										width: 75px; height:75px;

										@include respond-to(small){
											right: -60px; top: -40px;
											width: 150px; height: 150px;
										}
									}

									&-top-right {
										position: absolute; z-index: 0;
										right:-30px; top:-20px;

										@include respond-to(small){
											right: -16%;
											top: -13%;
										}
									}

									&-top-left {
										position: absolute; z-index: 0;
										left: 15px;
										top: -34px;

										@include respond-to(small){
											left: 2%;
											top: -15%;
										}
									}

									&-bottom-right {
										position: absolute; z-index: 0;
										right: 20px;
										bottom: -40px;

										@include respond-to(small){
											right: 2%;
											bottom: -18%;
										}
									}

									&-bottom-left {
										position: absolute; z-index: 0;
										left: 30px;
										bottom: -25px;

										@include respond-to(small){
											left: 10%;
											bottom: -20%;
										}
									}


									&-circle {
										width: 65px;
										height: 65px;

										@include respond-to(small){
											width: 35%;
											height: 35%;
										}
									}

									&-line {
										width: 70px;
										height: 13px;

										@include respond-to(small){
											width: 20%;
											height: 20%;
										}
									}

									&-bg {
										width: 65px;
										height: 65px;

										@include respond-to(small){
											width: 30%;
											height: 30%;
										}
									}
								}
							}

							&-wte {

								.svg {

									&-top-right {
										position: absolute; z-index: 0;
										right:-30px; top:-20px;
										width: 75px; height:75px;

										@include respond-to(small){
											right: -60px; top: -40px;
											width: 150px; height: 150px;
										}
									}

									&-bottom-left {
										position: absolute; z-index: 0;
										background: linear-gradient(180deg, #FF4040 0%, rgba(255, 255, 255, 0) 100%);
										opacity: 0.4;
										transform: rotate(-45deg);
										width: 80px;
										height: 80px;
										left: -20px;
										bottom: -20px;
										border-radius: 10px;

										@include respond-to(small){
											width: 160px;
											height: 160px;
											left: -40px;
											bottom: -40px;
											border-radius: 20px;
										}
									}

									&-bottom-center {
										position: absolute; z-index: 0;
										left: 50%;
										transform: translateX(-50%);
										bottom: -20%;

										&.time-bar {
											bottom: -27%;
										}

									}


									&-circle {
										width: 65px;
										height: 65px;

										@include respond-to(small){
											width: 35%;
											height: 35%;
										}
									}

									&-line {
										width: 70px;
										height: 13px;

										@include respond-to(small){
											width: 20%;
											height: 20%;
										}
									}

									&-bg {
										width: 65px;
										height: 65px;

										@include respond-to(small){
											width: 30%;
											height: 30%;
										}
									}
								}
							}
						}

						.thumb-div-image {
							position: relative;
							width: 100%;
							height: 100%;
							background-position: center center;
							background-repeat: no-repeat;
							background-size: cover;
							background-color: white;
							border-top-left-radius: 16px;
							border-top-right-radius: 16px;
							border-bottom-right-radius: 16px;
							border-bottom-left-radius: 0;
							z-index: 2;

							@include respond-to(small){
								border-top-left-radius: 33px;
								border-top-right-radius: 33px;
								border-bottom-right-radius: 33px;
							}
						}
					}
				}
			}
		}
		
		// główny na profilu
		&.academy-top-event {
			
			.images-row {
				margin: 0 0 40px auto;
				
				.inner-block {
					
					.inner-thumb-block {
						max-width:100%;
						margin-bottom: 30px;
						box-shadow: 0 120px 100px -90px rgba(0,0,0,0.02), 0 100px 90px -80px rgba(0,0,0,0.08), 0 50px 40px -30px rgba(0,0,0,0.05), 10px 30px 22px 0 rgba(0,0,0,0.03);
						border-top-left-radius: 18px;
						border-top-right-radius: 18px;
						border-bottom-right-radius: 18px;
						border-bottom-left-radius: 0;
						background-color: #f5eee5;
						border: 1px solid #f0e0e0;

						@include respond-to(small){
							border-top-left-radius: 35px;
							border-top-right-radius: 35px;
							border-bottom-right-radius: 35px;
						}

						.svg-up{
							position: absolute; z-index: 0; 
							right:-30px; top:-20px;
							width: 75px; height:75px;
							
							@include respond-to(small){
								right: -60px; top: -40px;
								width: 150px; height: 150px;
							}
						}
						
						.left-bottom-red-background {
							z-index: 0;
							position: absolute;
							background: linear-gradient(180deg, #FF4040 0%, rgba(255, 255, 255, 0) 100%);
							opacity: 0.4;
							transform: rotate(-45deg);
							width: 80px;
							height: 80px;
							left: -20px;
							bottom: -20px;
							border-radius: 10px;
							
							@include respond-to(small){
								width: 160px;
								height: 160px;
								left: -40px;
								bottom: -40px;
								border-radius: 20px;
							}
						}

						.thumb-div-image {
							position: relative;
							width: 100%;
							height: 100%;
							background-position: center center;
							background-repeat: no-repeat;
							background-size: cover;
							background-color: white;
							border-top-left-radius: 16px;
							border-top-right-radius: 16px;
							border-bottom-right-radius: 16px;
							border-bottom-left-radius: 0;
							z-index: 2;

							@include respond-to(small){
								border-top-left-radius: 33px;
								border-top-right-radius: 33px;
								border-bottom-right-radius: 33px;
							}
						}
					}
				}
			}
		}
		
		// newsy
		&.news-thumbs {
			.images-row {
				.carousel {
					&.inner-block {
						@include respond-to(small) { // 510 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(medium) { // 690 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(large) { // 930 w kolumnie, 
							padding: 0 30px;
						}
						@include respond-to(big) { // 1110 w kolumnie, 
							padding: 0 30px;
						}
						.flickity-viewport { // 450, 630, 870, 1050
							.carousel-cell {
								@include respond-to(small) { 
									width: 150px;
									padding: 0 5px;

									.inner-thumb-block {
										/*width: 140px;*/
										/*height: 79px;*/
									}
								}
								@include respond-to(medium) { 
									/*width: 210px;*/
									padding: 0 10px;

									.inner-thumb-block {
										/*width: 190px;*/
										/*height: 107px;*/
									}
								}
								@include respond-to(large) { 
									/*width: 290px;*/ 
									padding: 0 10px;

									.inner-thumb-block {
										/*width: 270px;*/
										/*height: 152px;*/
									}
								}
								@include respond-to(big) { 
									width: 350px; 
									padding: 0 10px;

									.inner-thumb-block {
										/*width: 330px;*/
										/*height: 185px;*/
									}
								}
								.inner-block {
									width: 100%;
									height: 100%;
									margin: 0 10px;
									padding: 10px;
									border-radius: 20px;
									background-color: color(silver);
									
									.inner-thumb-block {
										
										img {
											border-radius: 12px;
										}
										
										iframe {
											border-radius: 12px;
										}
									}
									
									.inner-content-block {
										
										p {	
											
											> .img-rounded {
												height: 30px;
											}
										}
										
										ol {
											padding-left: 20px;
											
											li {
												line-height: 1.16em;
												margin-bottom: 5px;
											}
										}
									}
								}
							}
							.flickity-slider{
								height: auto;
								
								&:after {
									content: '';
									clear: both;
								}
							}
						}						
						.flickity-prev-next-button {
							@include respond-to(small){
								top: 40px;						
							}
							@include respond-to(medium){
								top: 54px;						
							}
							@include respond-to(large){
								top: 76px;						
							}
							@include respond-to(big){
								top: 93px;						
							}
						}
					}
				}
			}
		}
	}
}

/*
	Default Modal, Card, Menu
 */

.modal {

	.backOutUp {
		-webkit-animation: backOutUp 0.5s;
		animation: backOutUp 0.5s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
	}

	.backInUp {
		-webkit-animation: backInUp 0.5s;
		animation: backInUp 0.5s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
	}

	.backOutDown {
		-webkit-animation: backOutDown 0.5s;
		animation: backOutDown 0.5s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
	}

	.backInDown {
		-webkit-animation: backInDown 0.5s;
		animation: backInDown 0.5s;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	.card {
		border-color: transparent;

		&.btn-hover:hover {
			-webkit-animation: hover 1200ms linear 2 alternate;
			animation: hover 1200ms linear 2 alternate;
		}

		.card-header {
			border-bottom: shade(silver,5);
		}

		.card-body {

			&.card-link {

				&.card-disabled {
					opacity: 0.5;
				}

				cursor: pointer;
				text-decoration: none;

				.icon {
					padding: 5px 18px;
				}

				&:hover .collapse-title-primary {
					-webkit-animation-name: change;
					-webkit-animation-duration: 1s;
					animation-name: change;
					animation-duration: 1s;
					animation-fill-mode: forwards;
				}

				&:hover .collapse-title-secondary {
					-webkit-animation-name: change;
					-webkit-animation-duration: 1s;
					animation-name: change;
					animation-duration: 1s;
					animation-fill-mode: forwards;
				}

				.collapse-title {
					height: 24px;
					overflow: hidden;

					.collapse-title-primary, .collapse-title-secondary {
						font-size: 18px;
					}
				}

				@-webkit-keyframes opacity {
					0%, 100% {opacity:0;}
					50% {opacity:1;}
				}

				@-webkit-keyframes change {
					0% {transform:translate3d(0,0,0);}
					100% {transform:translate3d(0,-100%,0);}
				}

				@-o-keyframes opacity {
					0%, 100% {opacity:0;}
					50% {opacity:1;}
				}

				@-o-keyframes change {
					0% {transform:translate3d(0,0,0);}
					100% {transform:translate3d(0,-100%,0);}
				}

				@-moz-keyframes opacity {
					0%, 100% {opacity:0;}
					50% {opacity:1;}
				}

				@-moz-keyframes change {
					0% {transform:translate3d(0,0,0);}
					100% {transform:translate3d(0,-100%,0);}
				}

				@keyframes opacity {
					0%, 100% {opacity:0;}
					50% {opacity:1;}
				}

				@keyframes change {
					0% {transform:translate3d(0,0,0);}
					100% {transform:translate3d(0,-100%,0);}
				}
			}

		}

		.card-footer {
			padding: 0 1.25rem;
			background-color: white;
			border-top-color: transparent;
			
			.btn {
				margin: -1px 0;
				border-color: transparent;
				margin-top: 5px;
				margin-bottom: 10px;
			}
		}
		
		&:last-of-type {
			border-bottom-color: transparent;
		}
	}
	
	.accordion {
		
		&:last-child {
			border-bottom-color: transparent;
		}
	}
}