.jq-message {
  box-sizing: border-box;
  width: auto;
  margin: 6px 0 0 0;
  padding: 6px 16px;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  font: font(base);
  position: relative;
  display: none;
  
  &.error {
    border-color: shade(accent, 20);
    background-color: tint(accent, 10);
    color: color(message-white);
  }

  &.alert {
    border-color: color(accent);
    background-color: tint(accent, 95);
    color: color(accent);
  }

  &.info {
    border-color: color(active);
    background-color: tint(active, 95);
    color: color(active);
  }
  
  .message-close {
    position: absolute;
    top: 0;
    right: 3px;
    color: black;
    cursor: pointer;
  }
}