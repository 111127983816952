$themes: (
        theme-academy color(blue-light) color(blue-very-light) white color(red) color(red),
        theme-employers color(orange) color(purple) white color(red) color(red),
        theme-foodtrade #6da626 #679330 #f5f5f5 #eeeeee #f5f5f5
        //Name, Primary, Secondary, Third/Font, Fourth, Fifth, Sixth,
) !default;


@mixin theme($name, $primary, $secondary, $third, $fourth, $fifth) {
  .#{$name} {
    // Background
    // class '.#{$name}-bg', extended: '.before', '.after'
    @include background('primary', $primary);
    @include background('secondary', $secondary);
    @include background('third', $third);
    @include background('fourth', $fourth);

    // Background Gradient
    // class '.#{$name}-bg-grd', extended: '.before', '.after'
    @include background-gradient('primary', $primary, $secondary, $third);
    @include background-gradient('secondary', $fourth, $fifth, $third);
    //@include background-gradient('secondary', $secondary);
    //@include background-gradient('third', $third);
    //@include background-gradient('fourth', $fourth);

    //Background Outline Gradient
    @include background-outline-gradient('primary', $primary, $secondary, $third);
    @include background-outline-gradient('secondary', $fourth, $fifth, $third);

    // Color
    // class '.#{$name}-color', extended: '.before', '.after'
    @include color('primary', $primary);
    @include color('secondary', $secondary);
    @include color('third', $third);
    @include color('fourth', $fourth);
  }
}

//Globalnie zawiera dwa główne kolory z każdej dostępnej instancji
@mixin theme-global($name, $primary, $secondary, $third, $fourth, $fifth) {
    // Background
    // class '.#{$name}-bg-grd', extended: '.before', '.after'
    @include background-gradient('primary-' + $name, $primary, $secondary, $third);
    @include background-gradient('secondary-' + $name, $fourth, $fifth, $third);

    @include background-outline-gradient('primary-' + $name, $fourth, $fifth, $third);
    @include background-outline-gradient('secondary-' + $name, $fourth, $fifth, $third);
}

@mixin background($name, $color) {
  .#{$name}-bg {
    background: $color;

    .before {
      &::before {
        background: $color;
      }
    }

    .after {
      &::after {
        background: $color;
      }
    }
  }
}

@mixin background-gradient($name, $color-primary, $color-secondary, $font-color) {
  .#{$name}-bg-grd {
    color: $font-color;

    &.default {
      background: linear-gradient(220deg, $color-primary, $color-secondary);
    }

    &.before {
      &:before {
        background: linear-gradient(220deg, $color-primary, $color-secondary);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      }
    }
  }
}

@mixin background-outline-gradient($name, $color-primary, $color-secondary, $font-color) {
  .#{$name}-bg-outline-grd {
    color: $font-color;

    &.default {
      background: linear-gradient(220deg, $color-primary, $color-secondary);
    }

    &.before {
      &:before {
        background: linear-gradient(220deg, $color-primary, $color-secondary);
      }
    }
  }
}

@mixin color($name, $color) {
  .#{$name}-color {
    color: $color;

    .before {
      &::before {
        color: $color;
      }
    }

    .after {
      &::after {
        color: $color;
      }
    }
  }
}


@each $theme, $primary, $secondary, $third, $fourth, $fifth in $themes {
  @include theme($theme, $primary, $secondary, $third, $fourth, $fifth);
  @include theme-global($theme, $primary, $secondary, $third, $fourth, $fifth);
}
