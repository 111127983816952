.btn-wt {
    height: fit-content;
    width: fit-content;
    white-space: nowrap;
    background-color: color(none);
    border-color: color(none);
    font: 400 15px/35px "Poppins";
    z-index: 1;

    &:focus, &:active {
        outline: none !important;
        box-shadow: none;
    }

    &.btn-hover {
        &:not([disabled]):hover {
            -webkit-animation: hover 1200ms linear 2 alternate;
            -moz-animation: hover 1200ms linear 2 alternate;
            animation: hover 1200ms linear 2 alternate;
        }
    }

    //&:disabled,
    //&[disabled]{
    //    -webkit-filter: contrast(20%) grayscale(100%) opacity(30%);
    //    filter: contrast(20%) grayscale(100%) opacity(30%);
    //}

    &.size {

        &.bt-1 {
            font-size: 10px;
        }

        &.bt-2 {
            font-size: 20px;
        }

        &.bt-3 {
            font-size: 30px;
        }

        &.bt-4 {
            font-size: 40px;
        }

        &.bt-5 {
            font-size: 50px;
        }

        &.bt-6 {
            font-size: 60px;
        }
    }

    &.text-transform {

        &-uppercase {
            text-transform: uppercase;
        }
    }

    &.enter {
        margin: auto;
    }

    &.no-center {
        margin: 0!important;
    }

    &.no-padding {
        padding: 0!important;
    }

    &.text-size-20 {
        font-size: 20px;
    }

    &.text-size-30 {
        font-size: 30px;
    }

    &.text-size-40 {
        font-size: 40px;
    }

    &.btn-gradient {
        position: relative;
        padding: 15px 16px;

        &:before {
            content:"";
            position: absolute;
            z-index: -1;
            @include position(0, 0, 0, 0);
            padding: 2px;
            border-radius: 8px;
            -webkit-mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
        }

        &.circle {
            &:before {
                border-radius: 50%;
            }
        }

        &.shadow-border {
            &:before {
                box-shadow: 0 0 3px 0 white inset, 0 0 3px 0 black;
            }
        }

        &.rectangle {
            width: 50px;
            height: 50px;
            position: relative;

            &.small {
                font-size: 0.7rem;
                width: 25px;
                height: 25px;
            }

            &.medium {
                width: 41px;
                height: 41px;
            }

            &.large {
                width: 75px;
                height: 75px;
            }

            &.xl {
                width: 120px;
                height: 120px;
            }

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.primary {
            color: color(white);
            text-transform: uppercase;
            font-weight: 550;

            &:before {
                background: linear-gradient(220deg, color(orange), color(purple));
            }
        }

        &.secondary {
            color: color(white);
            text-transform: uppercase;
            font-weight: 550;

            &:before {
                background: linear-gradient(220deg, color(blue-very-light), color(blue-dark));
            }
        }
        
        &.third {
            color: color(white);
            text-transform: uppercase;
            font-weight: 550;

            &:before {
                background: linear-gradient(220deg, color(orange-light), color(orange-dark));
            }
        }

        &.fourth {
            color: color(white);
            text-transform: uppercase;
            font-weight: 550;

            &:before {
                background: linear-gradient(220deg, #f7f7f7, #f0f0f0);
            }
        }
    }










    &.btn-outline-gradient {
        position: relative;
        padding: 15px 16px;

        &.icon {
            width: 47.13px;
            padding: 8px 0;
        }

        >.text {
            display: none;
        }

        &:before {
            content:"";
            position: absolute;
            @include position(0, 0, 0, 0);
            padding: 2px;
            border-radius: 8px;
            background-color: black;
            -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
        }

        &.circle {
            &:before {
                border-radius: 50%;
            }
        }

        &.shadow-border {
            &:before {
                box-shadow: 0 0 3px 0 white inset, 0 0 3px 0 black;
            }
        }

        &.primary {
            background: linear-gradient(to right, color(orange), color(purple));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
                background: linear-gradient(to right, color(orange), color(purple));
            }
        }

        &.secondary {
            background: linear-gradient(to right, color(blue-dark), color(blue-very-light));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
                background: linear-gradient(to right, color(blue-dark), color(blue-very-light));
            }
        }

        &.third {
            background: linear-gradient(to right, color(dark), color(gray-dark));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:before {
                background: linear-gradient(to right, color(dark), color(gray-dark));
            }
        }
        
    }

    &.btn-outline-gradient-dropdown {
        padding: 8px 15px;
        background-image: url("/build/images/buttons/button_dropdown_primary.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        &:hover {
            -webkit-animation: hover 1200ms linear 2 alternate;
            -moz-animation: hover 1200ms linear 2 alternate;
            animation: hover 1200ms linear 2 alternate;
        }

        span {
            background: linear-gradient(to right, color(orange), color(purple));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &.third {
            background-image: url("/build/images/buttons/button_dropdown_third.svg");
        }
    }





    // Przycisk koloru
    &.btn-color {
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 600;

        &.rectangle {
            width: 50px;
            height: 50px;
            position: relative;

            &.small {
                font-size: 0.7rem;
                width: 25px;
                height: 25px;
            }

            &.medium {
                width: 41px;
                height: 41px;
            }

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.border-outline {

            &.border-outline-small {
                border: 1px solid;
            }

            &.border-outline-medium {
                border: 3px solid black;
            }

            &.border-outline-green {
                border-color: rgba(109, 166, 38, 0.25);
            }

        }

        &.primary {
            background-color: color(red);
            color: color(white);
        }

        &.secondary {
            background-color: color(blue);
        }

        &.third {
            background-color: #E5E5E8;
        }

        &.green {
            background-color: #d4edda;
            color: color(white);
        }

        &.blue {
            background-color: #3592FF;
            color: color(white);
        }

        &.silver {
            background-color: shade(silver, 5);
        }

        &.white {
            background-color: color(white);
        }

        &.theme-2 {
            border-radius: 5px;
            padding: 0 10px;
            text-align: center;
            transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
            color: #6da626;
        }
    }

    // Przycisk zewnętrznej ramki w kolorze
    &.btn-outline-color {
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 600;
        background-color: transparent;
        border-width: 2px;
        border-style: solid;

        &.primary {
            border-color: color(red);
            color: color(red)!important;
        }
    }


}