@charset "utf-8";

main {
	min-height: calc( 100vh - 448px );
	//margin-top: 50px;
	padding-top: 80px;

	.btn-primary {
		background-color: color(blue);
		border-color: color(blue);
	}

	a {
		color: color(blue);
		.active {
			color: color(green);
		}
	}



}