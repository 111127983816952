@charset 'utf-8';

main {
	.main-top {
		.page-header {
			
			.h-1 {
				text-align: center;

				@include respond-to(small){
					text-align: left;
				}
			}
		}
	}
}