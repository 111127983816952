@charset 'utf-8';

/*#region Colors */
//@background: #ffffff;
//@text: #262c31;
//@button: #437adc;
//@buttonText: rgba(255,255,255,1);
///*#endregion*/
//
//// mobile/desktop breakpoint
//@breakpoint: 960px;

// selects the widget root element


#cookie-info {
	z-index: 9999;
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	text-align: center;
	font-size: 0;

	.container-cookie {
		display: inline-block;
		padding: 15px;
		width: auto;
		margin: 0 auto;
		border-radius: 15px;
		background: #ffffff;
		box-shadow: -2px 2px 5px 0px rgba(0,0,0,0.27);

		.body {
			display: inline-block;
			clear: both;
			font-family: Poppins, sans-serif;
			font-size: 14px;
			line-height: 1.15;
			color: rgba(38,44,49,1);
			flex-direction: row;
			
			p {
				margin: 5px auto;
			}
		}

		.buttons{
			display: inline-block;
			text-align: center;
			clear: both;
			margin: 10px auto 3px;

			.cookie-btn {
				font-family: "Open Sans", sans-serif;
				font-size: 14px;
			}
		}
	}

}




//#cookie-info {
//
//    p {
//        margin: 0;
//        padding: 0;
//        display: inline-block;
//    }
//
//    .cookie-btn {
//        cursor: pointer;
//        color: tint(blue, 75);
//        border-radius: 50%;
//        font-size: 22px;
//        font-weight: bold;
//        line-height: 14px;
//        padding: 0;
//        text-align: center;
//        margin: -20px 5px 20px 5px;
//
//        &:hover {
//            color: tint(blue, 99);
//        }
//    }
//}

