body {



  // ROUND SHAPE CORRECT CHECKBOX
  .row {
    &.radio-row {

      padding-top: calc(0.375rem + 1px);
      padding-bottom: calc(0.375rem + 1px);

      .radio:not(old) {
        /* schowany input */
        position: absolute;
        z-index: -1;
        top: 0.25rem;
        left: 0;
        width: 1rem;
        height: 1rem;
        padding: 0;
        opacity: 0;
      }

      .radio:not(old) + .radio-inline{
        /*	label w pseudo-input */
        display: block;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        margin-left: -1.5rem;
        padding-left: 1.5rem;
        line-height: 1.5rem;
        z-index: 5;

        span {
          /*  pole wyboru w pseudo-input */
          display: block;
          float: left;
          border-radius: 50%;
          background-color: white;
          padding: 0;
          width: 1.5rem;
          height: 1.5rem;
          font-size: 1rem;
          line-height: 1rem;
          -webkit-box-shadow:inset 0 0 0 1px color(light-gray);
          -moz-box-shadow:inset 0 0 0 1px color(light-gray);
          box-shadow:inset 0 0 0 1px color(light-gray);

          i {
            display: none;
          }
        }

      }

      .radio:not(old):checked + .radio-inline {
        /*				 label w pseudo-input dla zaznaczonego inputa */
        color: color(dark);

        span {
          /*					 pole wyboru w pseudo-input dla zaznaczonego inputa */
          background-color: color(blue);
          position: relative;
          display: block!important;
          box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
          border: none;

          i {
            display: block;
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  // GŁÓWNY KATALOG DO ZMIANY
  form {

    .form-control,
    .form-control-file {

      .custom-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 5px;
        border: 1px solid #dfdfdf;
        border-radius: 2px;
        margin-right: 2rem;
        padding: 1rem;
        padding-right: 2rem;
      }


      &.style-1 {
        border-radius: 8px;
        background-color: #f7f7f7;
        border: none;
      }

      &.style-2 {
        border-radius: 8px;
        background-color: white;
        border: none;
      }

      //Podstawowy required
      &::placeholder {
        color: color(light-gray);
      }
	  
	  &.required {
		  
		  &::placeholder {
			  color: color(orange-light);
		  }
	  }


    }

    .clear-fields {
      cursor: pointer;
      font-weight: bold;
      color: color(red);
    }
    .clear-fields:hover {
      color: shade(red,10);
    }
  }

  // SQUARE SHAPE CORRECT CHECKBOX
  .custom-control-input {
    width: 1.2rem;
    height: 1.2rem;
  }

  .custom-control-label {
    cursor: pointer;

    &.style-1 {
      width: 1.2rem;
      height: 1.2rem;

      &::before {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 4px;
        top: 0;
        left: 0;
      }

      &::after {
        width: 1.2rem;
        height: 1.2rem;
        top: 0;
        left: 0;
      }
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: color(blue);
    background-color: color(blue);
  }
  //



}