/*
display medium and larger (tablet and computer)
*/
.min-sm-inline,
.min-sm-inline-block,
.min-sm-block,
.min-md-inline,
.min-md-inline-block,
.min-md-block,
.min-ld-inline,
.min-ld-inline-block,
.min-ld-block,
.min-xl-inline,
.min-xl-inline-block,
.min-xl-block {
    display: none;
}
@include respond-to(small) {
    .min-sm-block {
        display: block;
    }
    .min-sm-inline-block {
        display: inline-block;
    }
    .min-sm-inline {
        display: inline;
    }
}
@include respond-to(medium) {
    .min-md-block {
        display: block;
    }
    .min-md-inline-block {
        display: inline-block;
    }
    .min-md-inline {
        display: inline;
    }
}
@include respond-to(large) {
    .min-ld-block {
        display: block;
    }
    .min-ld-inline-block {
        display: inline-block;
    }
    .min-ld-inline {
        display: inline;
    }
}
@include respond-to(big) {
    .min-xl-block {
        display: block;
    }
    .min-xl-inline-block {
        display: inline-block;
    }
    .min-xl-inline {
        display: inline;
    }
}

/* widoczne tylko smartfony w pionie */
.hidden-sm {

    @include respond-to(small) {
        display: none;
    }
}
/* widoczne tylko sm i mniejsze */
.hidden-md {

    @include respond-to(medium) {
        display: none;
    }
}

.sf-toolbar {
    display: none;
}

.pull-left,
.pull-right {
    float: none;
}
@include respond-to(medium) {
    .pull-right {
        float: right;
    }
    .pull-left{
        float: left;
    }
}

.input-group-append {
	
	.btn {
		margin-left: 0;
		margin-right: 0;
	}
}

.vertical-center {
    min-height: 100%;
    display: flex;
    align-items: center;
}

.form-control-plaintext {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 1rem;
}
.display-none {
    display: none;
}
.display-inline {
    display: inline;
}
.display-inline-block {
    display: inline-block;
}
.display-block {
    display: block;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
.absolute-right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}
.absolute-right-2 {
    position: absolute;
    right: 24px;
    top: 0;
    z-index: 10;
}
.absolute-right-3 {
    position: absolute;
    right: 48px;
    top: 0;
    z-index: 10;
}
.absolute-right-4 {
    position: absolute;
    right: 72px;
    top: 0;
    z-index: 10;
}
.absolute-right-5 {
    position: absolute;
    right: 96px;
    top: 0;
    z-index: 10;
}
.absolute-left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}
.absolute-left-2 {
    position: absolute;
    left: 24px;
    top: 0;
    z-index: 10;
}
.absolute-left-3 {
    position: absolute;
    right: 48px;
    top: 0;
    z-index: 10;
}
.absolute-left-4 {
    position: absolute;
    right: 72px;
    top: 0;
    z-index: 10;
}
.absolute-left-5 {
    position: absolute;
    right: 96px;
    top: 0;
    z-index: 10;
}
span.admin-info {
    border: 1px solid color(blue);
    padding: 2px 4px;
    background-color: white;
    color: schade(blue,50);
    font-size: 11px;
    line-height: 1.15;
    border-radius: 4px;
}
.bg-trans {
	background-color: transparent !important;
}

.scale-out-center {
    -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}