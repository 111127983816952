@charset 'utf-8';

/* nagłówek witryny */
body {

    >header {
        height: auto;
        position: fixed; 
        top: 0; 
        width: 100%; 
        z-index: 99;
        margin: 0;

        .navbar {
            height: 75px;
            padding: 0 16px;
            justify-content: left;
			
            &.theme-1 {
                background-color: rgba(255,255,255,0.95);
            }

            &.theme-2 {
                background-color: rgba(252, 252, 252, 0.9);
                border-top: 12px solid #6da626;
                border-bottom: 1px solid rgba(109, 166, 38, 0.25);
            }

            /* od tego momentu nawigacja główna jest widoczna */
            @include respond-to(medium) {
                justify-content: space-between;
            }

            .navbar-toggler {
                margin: 0;
            }

            .navbar-brand {
                white-space: nowrap;
                padding: 0;
                width: auto;
                margin: 8px auto 8px 0;
                display: block;

                img {
                    margin: 0;
                    padding: 0;
                    width: calc( 50vw - 20px );
                    min-width: 150px;
                    max-width: 200px;

                    @include respond-to(small){
                    }
                }			
            }

            #navbarSupportedContent {
                flex-grow: 0;

                .menu-item {
                    margin-right: 1rem;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                > .navbar-nav {
                    padding-bottom: 15px;

                    @include respond-to(large) {
                        padding-bottom: 0;
                    }

                    > .nav-item {
                        width: 100%;

                        @include respond-to(large) {
                            width: auto;

                            > .dropdown-menu {
                                border-radius: 16px 0 16px 16px;
                                padding: 15px 8px;
                                top: 3.5em;
                                left: -4em;
                            }
                        }

                        &.nav-language {

                            span .plate {
                                background-color: transparent;
                                width: 50px;
                                height: 18px;
                                display: block;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                margin: 1px 1px;
                                border-radius: 8px;
                                //border: 1px solid black;
                                //box-shadow: 0 0 1.6px 0 black inset, 0 0 1.6px 0 black;
                                z-index: 0;
                                opacity: 20%;
                            }

                            .flag {
                                border-radius: 50%;
                                border: 1px solid black;
                                box-shadow: 0 0 1.6px 0px black inset, 0 0 1.6px 0px black;
                                z-index: 1;
                                position: relative;
                                object-fit: cover;
                            }
                        }

                        > .nav-link {
                            font-weight: 400;
                            font: font(menu-default-link);
							
							&.active {
								color: color(active);
							}
                        }

                        &.locale-chooser{
                            margin-left: 5px;

                            &:first-of-type {
                                margin-left: 20px;
                            }

                            .nav-link {

                                &.set-lang {
                                    padding: 2px;
                                    margin: 2px;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    background-color: tint(cancel,50);
                                    border: 1px solid color(cancel);
                                    opacity: 0.65;

                                    &.active {
                                        cursor: default;
                                        background-color: shade(cancel, 20);
                                        border-color: color(dark);
                                        opacity: 1;

                                        &:hover {									
                                            background-color: color(dark);
                                        }

                                    } 

                                    &:hover {
                                        background-color: color(cancel);
                                        opacity: 1

                                    }
                                }
                            }
                        }

                    }

                }
            }

            &::before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                opacity: .95;
                background-color: white;
                z-index: -1;
            }


            .above-line {
                content: '';
                position: absolute;
                height: 4px;
                width: 100%;
                top: 0;
                left: 0;
            }


        }

        #alerts {

        }
    }
	
	&#adminPage {
		
		>header {
			
			>.navbar {
				height: auto;
			}
		}
		
	}
}
