@charset 'utf-8';

body {

    >footer {

        &.push {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        a {
            color: gray;
            font-size: 12px;
            font-weight: 500;

            &:hover {
                color: deepskyblue;
            }
        }

        .clickable {
            cursor: pointer;
        }

        .icon {
            width: 30px;
        }

        .btn-hover:hover {
            -webkit-animation: hover 1200ms linear 2 alternate;
            animation: hover 1200ms linear 2 alternate;
        }

        .tooltip {
            position: absolute;
            top: -50px;
            margin: 0 auto;
            background: #373737;
            padding: 10px 15px;
            color: #fff;
            font-size: 12px;
            border-radius: 4px;
            opacity: 0;
            white-space: nowrap;
            left: 0;
            right: 0;

            &.active {
                animation: appearUp 1s ease;
            }
        }

        .social-media {
            .social-media-icon {
                a {
                    font-size: 20px!important;
                }
            }
        }

    }

}
